import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { DISCOUNT } from '../variables';
import { useDispatch } from 'react-redux';
import BasicStakeConfig from './BasicStakeConfig';
import { ContractContext } from 'web3/WagmiListener';
import SwipeableViews from 'react-swipeable-views';
import { setSnackbar } from 'redux/actions/snackbar';
import { DEX, MIN_STAKE_AMOUNT } from 'utils/variables';
import AdvancedStakeConfig from './AdvancedStakeConfig';
import StakeConfirmation from './StakeConfirmation/StakeConfirmation';
import AutostakeApprovalModal from 'components/Modals/AutostakeApprovalModal/';
import { trackStakeEvent, trackError, trackAcceleratorEvent } from 'utils/analytics';

const StakeCard = ({
    days,
    token,
    tokens,
    amount,
    balance,
    animOff,
    loading,
    confetti,
    setToken,
    shareRate,
    stakeInfo,
    usingDivs,
    tokenPrice,
    isApproved,
    setUsingDivs,
    minStakeDays,
    onChangeDays,
    interfaceType,
    calculateBonus,
    onChangeAmount,
    setInterfaceType,
}) => {
    const fix = useRef();
    const interval = useRef();
    const dispatch = useDispatch();
    const { user, library, getWalletDetails, getContractInfo, loadingContractInfo, contractInfo } = useContext(ContractContext);

    const [open, setOpen] = useState(false);
    const [staking, setStaking] = useState(false);
    const [approving, setApproving] = useState(false);
    const [divsInToken, setDivsInToken] = useState(0);
    const [approvalOpen, setApprovalOpen] = useState(false);
    const [bonus, setBonus] = useState({ percent: 0, active: false });

    useEffect(() => {
        const _handleScroll = () => {
            if (open) setOpen(false);
        };
        document.addEventListener('scroll', _handleScroll);
        return () => {
            document.removeEventListener('scroll', _handleScroll);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const divToken = user?.ventureDivs?.find((vd) => vd.tokenAddress === token.address);
        if (divToken) setDivsInToken(divToken.interestEarnedToken);
    }, [token, user]);

    useEffect(() => {
        setBonus(
            calculateBonus({
                tokenAmount: +amount.value,
                days: +days.value,
            }),
        );

        // eslint-disable-next-line
    }, [days, amount, tokenPrice]);

    useEffect(() => {
        if (usingDivs) handleAmount({ target: { value: divsInToken } });
        else handleAmount({ target: { value: token.prefillAmount } });

        // eslint-disable-next-line
    }, [usingDivs]);

    const handleUsingDivs = (e) => {
        const check = e.target.checked;
        setUsingDivs(check);
    };

    const approve = () => {
        return new Promise(async (resolve, reject) => {
            setApproving(true);
            trackAcceleratorEvent('approve_button_press');

            try {
                await library.Accelerator.approve(token);
                dispatch(
                    setSnackbar({
                        type: 'success',
                        message: 'Approval successful! You can now complete your stake.',
                    }),
                );
                trackStakeEvent({
                    amount: amount.value,
                    days: days.value,
                    type: 'buy_stake_approval',
                });
                resolve();
            } catch (error) {
                console.log(error);
                trackAcceleratorEvent('approval_cancelled');
                dispatch(setSnackbar({ message: error.message }));
                trackError({ type: 'buy_stake_approval', description: error.message });
                reject();
            } finally {
                setApproving(false);
            }
        });
    };

    const stake = () => {
        const _days = days.value;
        const _amount = amount.value;

        // Check if the AXN amount is above the min stake amount
        if (stats[1].dataNumber < MIN_STAKE_AMOUNT) {
            dispatch(
                setSnackbar({
                    message: `You must stake at least ${MIN_STAKE_AMOUNT.toLocaleString()} AXN.`,
                }),
            );
            return;
        }

        return new Promise(async (resolve, reject) => {
            try {
                setStaking(true);
                trackAcceleratorEvent('stake_button_press');
                await library.Accelerator.buyAndStake(token, _amount, _days, usingDivs);

                // Success
                confetti();
                getContractInfo();
                getWalletDetails();
                setInterfaceType('done');
                trackAcceleratorEvent(null, _days, token.symbol, _amount * tokenPrice);
                trackStakeEvent({ amount: _amount, days: _days, type: 'buy_stake' });
                resolve();
            } catch (error) {
                console.log(error);
                if (error.message === 'approval') {
                    if (interfaceType === 'advanced') setApprovalOpen(true);
                    resolve();
                } else {
                    reject(error);
                    trackAcceleratorEvent('stake_cancelled');
                    dispatch(setSnackbar({ message: error.message }));
                    trackError({ type: 'buy_stake', description: error.message });
                }
            } finally {
                setStaking(false);
            }
        });
    };

    const stakeDisabled = () => {
        if (staking || !user) return true;
        if (usingDivs) return false;
        if (+amount.value === 0 || !amount.value || +amount.value > +balance) return true;
        if (+days.value < minStakeDays && interfaceType !== 'basic') return true;

        return false;
    };

    const handleMaxDays = () => {
        onChangeDays({ value: 5555, valid: true });
    };

    const handleMaxAmount = () => {
        const div = token.decimals === 8 ? 1e8 : 1e4;
        onChangeAmount({ value: Math.floor(balance * div) / div, valid: true });
    };

    const changeSlider = (val) => {
        if (interval.current) {
            clearInterval(interval.current);
        }

        handleDays({ target: { value: val } });
    };

    const handleAmount = (e) => {
        if (e.target.value * tokenPrice > 10000000) return;
        onChangeAmount({
            value: e.target.value,
            valid: e.target.float > 0,
        });
    };

    const handleDays = (e) => {
        const value = parseInt(e.target.value);

        if (value <= 5555) {
            onChangeDays({
                value,
                valid: !isNaN(value) && value >= minStakeDays,
            });

            // Update speed as days increases
            if (window.pJSDom && window.pJSDom[0] && !animOff) {
                const min_in = minStakeDays || 0;
                const max_in = 5555;

                const min_out = 1;
                const max_out = 3;

                const newSpeed = ((value - min_in) / (max_in - min_in)) * max_out;

                if (window.pJSDom) {
                    for (let i = 0; i < window.pJSDom.length; i++) {
                        window.pJSDom[i].pJS.particles.move.speed = Math.max(min_out, newSpeed);
                    }
                }
            }
        }
    };

    const handleChangeToken = (e) => {
        const t = e.target.value;
        if (t !== token.address) {
            setUsingDivs(false);
            setToken(tokens.find((token) => token.address === t));
        }
    };

    const stats = useMemo(() => {
        const is_b = bonus.active || bonus.percent >= 1.02 * 100 - 100;
        const currentPrice = loadingContractInfo ? 0.0001 : contractInfo?.axion?.usdPerAXN;

        const data = {
            principal: is_b ? bonus.principal : (amount.value * tokenPrice) / currentPrice,
            shares: is_b ? bonus.shares : +stakeInfo.shares,
            lpb: is_b ? bonus.lpb : +stakeInfo.lpb,
            total: is_b ? bonus.totalShares : +stakeInfo.totalShares,
        };

        return [
            {
                statTitle: 'BONUS AXN',
                suffix: '%',
                bonus: true,
                right: true,
                bottom: true,
                tooltip:
                    bonus.percent >= 1.02 * 100 - 100
                        ? `The Axion Accelerator allows you to buy AXN at a ${bonus.percent.toFixed(2)}% discount compared to ${DEX}.`
                        : `Congratulations! You are getting ${bonus.percent.toFixed(2)}% more AXN by using the Accelerator!`,
                data: is_b ? bonus.percent.toFixed(0) : 0,
            },
            {
                statTitle: 'Principal (AXN)',
                bonus: true,
                right: false,
                bottom: true,
                dataNumber: data.principal,
                data: `~ ${data.principal.numberWithCommas(0)}`,
            },
            {
                statTitle: 'Basic Shares',
                bonus: true,
                right: true,
                bottom: true,
                data: data.shares.numberWithCommas(0),
            },
            {
                statTitle: 'Longer Pays Better',
                bonus: true,
                right: false,
                bottom: true,
                data: data.lpb.numberWithCommas(0),
            },
            {
                statTitle: 'Global Share Rate',
                bonus: false,
                right: true,
                bottom: true,
                data: shareRate?.toFixed(4),
                tooltip: shareRate,
            },
            {
                statTitle: 'Total Shares',
                bonus: true,
                right: false,
                bottom: true,
                data: data.total.numberWithCommas(0),
            },
            {
                statTitle: 'Start Day',
                bonus: false,
                right: true,
                bottom: false,
                data: moment(stakeInfo.startDate).format('MMM DD, YYYY'),
            },
            {
                statTitle: 'End Day',
                bonus: false,
                right: false,
                bottom: false,
                data: moment(stakeInfo.endDate).format('MMM DD, YYYY'),
            },
        ];

        // eslint-disable-next-line
    }, [bonus, stakeInfo]);

    const _getStep = () => {
        switch (interfaceType) {
            case 'basic':
                return 1;
            case 'advanced':
                return 0;
            case 'done':
                return 2;
            default:
                return 0;
        }
    };

    const params = {
        user,
        days,
        bonus,
        open,
        stats,
        token,
        tokens,
        amount,
        staking,
        loading,
        balance,
        library,
        setToken,
        approving,
        usingDivs,
        shareRate,
        stakeInfo,
        isApproved,
        tokenPrice,
        divsInToken,
        onChangeDays,
        minStakeDays,
        interfaceType,
        onChangeAmount,

        stake,
        approve,
        setOpen,
        handleDays,
        changeSlider,
        handleAmount,
        stakeDisabled,
        handleMaxDays,
        handleUsingDivs,
        handleMaxAmount,
        setInterfaceType,
        handleChangeToken,
    };

    return (
        <React.Fragment>
            <SwipeableViews
                disabled
                index={_getStep()}
                ignoreNativeScroll
                animateHeight={true}
                containerStyle={{
                    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
                }}
                onLoad={() => {
                    if (!fix.current) {
                        fix.current = setTimeout(() => {
                            setInterfaceType('basic');
                            setInterfaceType('advanced');
                        }, 500);
                    }
                }}
            >
                <AdvancedStakeConfig {...params} />
                <BasicStakeConfig {...params} />
                <StakeConfirmation {...params} />
            </SwipeableViews>

            <AutostakeApprovalModal
                library={library}
                open={approvalOpen}
                onApprove={approve}
                onStake={() => stake()}
                onClose={() => setApprovalOpen(false)}
                token={{ ...token, price: tokenPrice }}
                stakeInfo={{ days: +days.value, amount: +amount.value }}
                axnPrice={loadingContractInfo ? 0.0001 : contractInfo?.axion?.usdPerAXN}
            />
        </React.Fragment>
    );
};

export default StakeCard;
