import React from 'react';
// Material UI
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
// styles
import styles from './styles';

const SkeletonListItem = ({ classes, ...props }) => {
    return (
        <Typography className={classes.root} variant={props.variant}>
            <Skeleton variant="text" className={classes.skeleton} style={{ width: props.width }} />
        </Typography>
    );
};

export default withStyles(styles)(SkeletonListItem);
