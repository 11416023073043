import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    option: {
        cursor: 'pointer',
        padding: `8px 12px`,
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            padding: `4px 6px`,
        },
    },
    active: {
        fontWeight: 500,
        backgroundColor: theme.brand.border,
        padding: `8px 12px`,

        [theme.breakpoints.down('sm')]: {
            padding: `4}px 6px`,
        },
    },
    options: {
        fontWeight: 300,
        userSelect: 'none',
    },

    basic: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    advanced: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
}));

export default useStyles;
