import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    nftName: {
        fontWeight: 300,
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    nft: {
        width: '100%',
        borderRadius: 10,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            boxShadow: `0px 0px 5px ${theme.brand.colliderBlue}`,
        },
    },
    selected: {
        //darken
        filter: 'brightness(20%)',
        boxShadow: `0px 0px 5px ${theme.brand.colliderOrange}`,
        '&:hover': {
            boxShadow: `0px 0px 5px ${theme.brand.colliderOrange}`,
        },
    },
}));

export default useStyles;
