import axios from 'axios';
import { base, routes } from 'api/requests';
import tokens from 'containers/Accelerator/tokens';
import { getPrice } from 'utils/get-market-prices';
import { SET_ACCELERATOR } from 'redux/reducers/accelerator';
import { CONTRACT_INFO } from 'utils/variables';

export const get = () => async (dispatch) => {
    const response = await Promise.all(tokens.map((t) => getPrice(t)));
    const payload = { tokenPrices: {}, stats: null };
    tokens.forEach((t, idx) => (payload.tokenPrices[t.symbol] = response[idx]));

    // Get axion price
    payload.tokenPrices['axn'] = await getPrice({ address: CONTRACT_INFO.AXN.ADDRESS });
    payload.tokenPrices['wBTC'] = await getPrice({ address: CONTRACT_INFO.Tokens.WBTC });

    // Get accelerator stats
    let stats = null;

    try {
        const statRequest = await axios(base + routes.getCoin);
        stats = statRequest.data.accelerator;
        if (stats) payload.stats = stats;
    } catch (e) {
        console.log('Error getting accelerator stats', e);
    }

    dispatch({
        payload,
        type: SET_ACCELERATOR,
    });

    return response;
};
