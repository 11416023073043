import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 500,
        fontSize: '1.2rem',
        marginBottom: theme.spacing(1),
    },
    openTx: {
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'underline',
        marginTop: theme.spacing(2),
    },
    collider: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
