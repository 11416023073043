import React from 'react';
import useStyles from './styles';
import { RENAME_MAX_LENGTH } from 'utils/variables';
import { Typography, TextField, InputAdornment } from '@mui/material';

const RenameStake = ({ data, name, setName }) => {
    const classes = useStyles();

    return (
        <div>
            <Typography>
                Set a custom name for your stake. This name is public and can be seen by anyone on the blockchain. It may take a few minutes
                for the new name to be updated across the network nodes and reflected in the stake table.
            </Typography>
            <div className={classes.textboxContainer}>
                <TextField
                    // sx={{ marginTop: 1 }}
                    value={name}
                    variant="outlined"
                    label="Stake Name"
                    onChange={setName}
                    className={classes.textbox}
                    placeholder={data?.stake?.name}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography className={classes.max}>
                                    {name.length} / {RENAME_MAX_LENGTH}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default RenameStake;
