import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        borderBottom: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noInputPadding: {
        padding: '0 !important',
    },
    inputs: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${theme.brand.border}`,
            borderRight: 'none',
        },
    },
    balance: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    maxLength: {
        marginTop: theme.spacing(0.5),
    },
    body: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    stat: {
        textAlign: 'center',
        flexBasis: '45%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            flexBasis: '43%',
        },
    },
    borderRight: {
        borderRight: `1px solid ${theme.brand.border}`,
    },
    borderTop: {
        borderTop: `1px solid ${theme.brand.border}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    sliderContainer: {
        textAlign: 'center',
    },
    slider: {
        width: '90% !important',
        marginTop: '2%',
        marginRight: '1%',
        [theme.breakpoints.down('sm')]: {
            width: '80% !important',
        },
    },
    bonusText: {
        fontWeight: 'bold',
        verticalAlign: 'top',
        color: theme.brand.JavaGreen,
    },
    bonusContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    bonusDescription: {
        padding: '2% 0',
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${theme.brand.border}`,
        },
    },
    includesBonusText: {
        color: theme.brand.JavaGreen,
    },
    bpd: {
        padding: '1%',
        borderTop: `1px solid ${theme.brand.border}`,
    },
    textfield: {
        color: theme.brand.NormalText,
    },
    noBorder: {
        border: 'none',
    },
    green: {
        color: theme.brand.JavaGreen,
    },
    na: {
        color: theme.palette.text.secondary,
    },
    phoenix: {
        flex: 1,
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    colliderOrange: {
        color: theme.brand.colliderOrange,
    },
    white: {
        color: theme.brand.NormalText,
    },
    gold: {
        color: '#ffd700',
    },
    silver: {
        color: '#8c8c8c',
    },
}));

export default useStyles;
