import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import useStyles from './styles';
import SharedDays from '../SharedDays';
import SharedAmount from '../SharedAmount';
import { HiOutlinePlus } from 'react-icons/hi';
import { InfoOutlined } from '@mui/icons-material';
import DEXLogo from 'assets/images/apeeswaplogo.png';
import AnimatedAtom from 'components/AnimatedAtom';
import SwipeableViews from 'react-swipeable-views';
import SharedSelectIcons from '../SharedSelectIcons/';
import SharedActionButton from '../SharedActionButton';
import SharedSlider from '../SharedSlider/SharedSlider';
import SharedSelect from '../SharedSelect/SharedSelect';
import SmartContract from 'assets/images/smartcontract.svg';
import SharedDivCheckbox from '../SharedDivCheckbox/SharedDivCheckbox';
import { Grid, Typography, Tooltip, useMediaQuery } from '@mui/material';
import { DEX } from 'utils/variables';

const SLIDES = {
    CURRENCY: 0,
    LENGTH: 1,
    APPROVAL: 2,
    SUMMARY: 3,
};

const BasicStakeConfig = (props) => {
    const classes = useStyles();
    const [approved, setApproved] = useState(false);
    const [step, setStep] = useState(SLIDES.CURRENCY);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        setApproved(props.isApproved);
    }, [props.isApproved]);

    const handleContinue = () => {
        if (step === SLIDES.CURRENCY) setStep(SLIDES.LENGTH);
        else if (step === SLIDES.LENGTH) setStep(approved || props.token.symbol === 'MATIC' ? SLIDES.SUMMARY : SLIDES.APPROVAL);
        else props.stake();
    };

    const handleBack = () => {
        if (step === SLIDES.SUMMARY) setStep(approved || props.token.symbol === 'MATIC' ? SLIDES.LENGTH : SLIDES.APPROVAL);
        else setStep(step - 1);
    };

    const handleApprove = async () => {
        try {
            await props.approve();
            setStep(SLIDES.SUMMARY);
            setApproved(true);
        } catch (err) {}
    };

    const handleStake = async () => {
        try {
            await props.stake();
            setStep(SLIDES.CURRENCY);
        } catch (err) {}
    };

    const CurrencySelectionSlide = () => (
        <Grid container direction="column" justify="space-between" className={classes.slide}>
            <Grid item>
                <div className={classes.padding}>
                    <div className={classes.boxes}>
                        {/* <div className={classes.iconsContainer}>
                            <SharedSelectIcons
                                token={props.token}
                                tokens={props.tokens}
                                handleChangeToken={props.handleChangeToken}
                            />
                        </div> */}
                        <SharedSelect
                            token={props.token}
                            tokens={props.tokens}
                            setOpen={props.setOpen}
                            handleChangeToken={props.handleChangeToken}
                            open={props.open && props.interfaceType === 'basic'}
                        />
                        <div className={classes.amount}>
                            <SharedAmount
                                amount={props.amount}
                                balance={props.balance}
                                disabled={props.usingDivs}
                                tokenPrice={props.tokenPrice}
                                handleAmount={props.handleAmount}
                                handleMaxAmount={props.handleMaxAmount}
                            />

                            <Grid container justify="space-between" direction="row-reverse" style={{ padding: 0, margin: 0 }}>
                                <Grid item>
                                    <Typography variant="h6" className={classes.balance}>
                                        Balance:
                                        {` ${props.balance.numberWithCommas(props.balance === 0 ? 0 : props.token.decimalsToDisplay)} ${
                                            props.token.symbol
                                        }`}
                                    </Typography>
                                </Grid>
                                {props.token?.divToken && props.divsInToken > 0 && (
                                    <Grid item>
                                        <SharedDivCheckbox
                                            token={props.token}
                                            checked={props.usingDivs}
                                            divsInToken={props.divsInToken}
                                            handleAmount={props.handleAmount}
                                            setChecked={props.handleUsingDivs}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <Grid container justify="space-evenly" alignItems="center" className={classes.pricesGrid}>
                        <Grid item xs={6}>
                            <center>
                                <img alt={DEX} src={DEXLogo} className={classes.uniswap} />
                                <Typography>{DEX} Price</Typography>

                                {isSmall ? (
                                    <Typography className={classes.summarySub}>
                                        {(+props.amount.value).numberWithCommas(props.token.decimalsToDisplay)} {props.token.symbol} is
                                        about
                                        <br />
                                        {props.bonus.axnBeforeBonus?.numberWithCommas(0)} AXN
                                    </Typography>
                                ) : (
                                    <Typography className={classes.summarySub}>
                                        {(+props.amount.value).numberWithCommas(props.token.decimalsToDisplay)} {props.token.symbol} gets
                                        you up to {props.bonus.axnBeforeBonus?.numberWithCommas(0)} AXN
                                    </Typography>
                                )}
                            </center>
                        </Grid>
                        <Grid item xs={6}>
                            <center>
                                <div className={classes.atom}>
                                    <AnimatedAtom active showCore />
                                </div>

                                <Typography>Accelerator Price</Typography>

                                {isSmall ? (
                                    <Typography className={classes.summarySub}>
                                        {(+props.amount.value).numberWithCommas(props.token.decimalsToDisplay)} {props.token.symbol} is
                                        about {props.bonus.principal?.numberWithCommas(0)} AXN
                                    </Typography>
                                ) : (
                                    <Typography className={classes.summarySub}>
                                        {(+props.amount.value).numberWithCommas(props.token.decimalsToDisplay)} {props.token.symbol} gets
                                        you up to {props.bonus.principal?.numberWithCommas(0)} AXN
                                    </Typography>
                                )}
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item style={{ padding: '2%' }}>
                <SharedActionButton
                    variant="continue"
                    user={props.user}
                    loading={props.staking}
                    onClick={handleContinue}
                    disabled={false}
                />
            </Grid>
        </Grid>
    );

    const StakeDaysSlide = () => (
        <Grid container direction="column" justify="space-between" className={classes.slide}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <div className={classes.padding}>
                        <SharedDays
                            days={props.days}
                            handleDays={props.handleDays}
                            handleMaxDays={props.handleMaxDays}
                            minStakeDays={props.minStakeDays}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.dateContainer}>
                        <Typography className={classes.basicStat}>{moment(props.stakeInfo.endDate).format('MMMM DD, YYYY')}</Typography>
                        <Typography className={classes.statSubtitle}>Stake End Date</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <SharedSlider
                        days={props.days}
                        paddingBottom="25px"
                        minStakeDays={props.minStakeDays}
                        changeSlider={props.changeSlider}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify="space-evenly" className={classes.sharesGrid} alignItems="center">
                    <Grid item>
                        <Tooltip
                            enterDelay={0}
                            enterTouchDelay={0}
                            title={`${props.bonus.active ? `Includes ${props.bonus.percent.toFixed(1)}% Accelerator bonus` : ''}`}
                        >
                            <div>
                                <Typography
                                    className={clsx(classes.basicStat, {
                                        [classes.summaryReward]: props.bonus.active,
                                    })}
                                >
                                    {(props.bonus.active ? props.bonus.shares : +props.stakeInfo.shares).numberWithCommas(0)}
                                </Typography>
                                <Typography className={classes.statSubtitle}>Basic Shares</Typography>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <HiOutlinePlus className={classes.plus} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div>
                            <Tooltip
                                enterDelay={0}
                                enterTouchDelay={0}
                                title={`${props.bonus.active ? `Includes ${props.bonus.percent.toFixed(1)}% Accelerator bonus` : ''}`}
                            >
                                <Typography
                                    className={clsx(classes.basicStat, {
                                        [classes.summaryReward]: props.days.value > 1,
                                    })}
                                >
                                    {(props.bonus.active ? props.bonus.lpb : +props.stakeInfo.lpb).numberWithCommas(0)}
                                </Typography>
                            </Tooltip>

                            <Typography className={clsx(classes.statSubtitle, classes.lpbStat)}>
                                Longer Pays Better&nbsp;
                                <Tooltip
                                    enterDelay={0}
                                    enterTouchDelay={0}
                                    title="Earn significantly more APR and Bitcoin rewards simply by staking longer."
                                >
                                    <InfoOutlined className={classes.infoIcon} />
                                </Tooltip>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justify="space-between" spacing={2} style={{ padding: '2%' }}>
                <Grid item xs={6}>
                    <SharedActionButton variant="back" onClick={handleBack} user={props.user} />
                </Grid>
                <Grid item xs={6}>
                    <SharedActionButton
                        variant="continue"
                        user={props.user}
                        loading={props.staking}
                        onClick={handleContinue}
                        disabled={false}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const ApprovalSlide = () => {
        if (approved || props.token.symbol === 'MATIC') return <div className={classes.slide}></div>;
        else {
            return (
                <Grid container direction="column" justify="space-between" className={classes.slide}>
                    <Grid item>
                        <div className={clsx(classes.padding, classes.approvalContainer)}>
                            <Typography align="center">
                                Since this is your first time spending {props.token.symbol} on the Axion Accelerator smart contract, you
                                will need to complete an approval transaction before the contract can create the stake.
                            </Typography>
                            <center>
                                <img src={SmartContract} className={classes.approveImage} alt="approve" />
                            </center>
                            <Typography align="center">
                                Your {props.token.symbol} will not leave your wallet during this step, but you will be charged a small gas
                                fee. Afterwards, you will be asked to complete the second transaction. To begin, click "Approve" below.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container justify="space-between" alignItems="center" spacing={2} style={{ padding: '2%' }}>
                        <Grid item xs={6}>
                            <SharedActionButton variant="back" onClick={handleBack} user={props.user} />
                        </Grid>
                        <Grid item xs={6}>
                            <SharedActionButton
                                variant="approve"
                                loading={props.approving}
                                onClick={handleApprove}
                                disabled={props.approving || !props.user}
                                user={props.user}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    };

    const SummarySlide = () => (
        <Grid container direction="column" justify="space-between" className={classes.slide}>
            <Grid item container alignItems="center" className={classes.borderBottom}>
                <Grid item xs={6} key={`stats-token`} className={clsx(classes.stat, classes.borderBottom, classes.borderRight)}>
                    <Typography
                        variant="h3"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img className={classes.summaryLogo} alt="logo" src={props.token.logoDark ?? props.token.logo} />
                        {props.token.symbol}
                    </Typography>
                    <Typography variant="h6">Input Token</Typography>
                </Grid>

                <Grid item xs={6} key={`stats-token-amount`} className={clsx(classes.stat, classes.borderBottom)}>
                    <Typography variant="h3">{Number(props.amount.value).numberWithCommas(props.token.decimalsToDisplay)}</Typography>
                    <Typography variant="h6">Input Amount</Typography>
                </Grid>

                {props.stats.map((s, index) => (
                    <Grid
                        item
                        xs={6}
                        key={`stats-${index}`}
                        className={clsx(classes.stat, {
                            [classes.borderRight]: s.right,
                            [classes.borderBottom]: s.bottom,
                        })}
                    >
                        <Tooltip title={s.tooltip ? s.tooltip : ''} arrow={true}>
                            <Typography
                                variant="h3"
                                className={clsx({
                                    [classes.bonusText]: props.bonus.active && s.bonus,
                                })}
                            >
                                {s.data}
                                {s.suffix ? s.suffix : ''}
                            </Typography>
                        </Tooltip>
                        <Typography variant="h6">{s.statTitle}</Typography>
                    </Grid>
                ))}
            </Grid>
            <Grid item container justify="space-between" spacing={2} style={{ padding: '2%' }}>
                <Grid item xs={6}>
                    <SharedActionButton variant="back" onClick={handleBack} user={props.user} />
                </Grid>
                <Grid item xs={6}>
                    <SharedActionButton
                        variant="stake"
                        onClick={handleStake}
                        loading={props.staking}
                        disabled={props.stakeDisabled()}
                        user={props.user}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <SwipeableViews
            disabled
            index={step}
            ignoreNativeScroll
            containerStyle={{
                height: 'auto !important',
                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
            }}
        >
            {CurrencySelectionSlide()}
            {StakeDaysSlide()}
            {ApprovalSlide()}
            {SummarySlide()}
        </SwipeableViews>
    );
};

export default BasicStakeConfig;
