import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        opacity: 0.95,
        boxShadow: theme._shadows.card,
        backgroundColor: theme.brand.CardBG,
        borderRadius: 4,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    actionDialogPaper: {
        maxWidth: 'calc(100% - 16px) !important',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: 85,
        },
    },
    titleIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
