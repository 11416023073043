import cloneDeep from 'lodash/cloneDeep';

const base = {
    totalBalance: 0,
    totalBalanceUsd: 0,
    totalBalanceUsdCents: 0,
    balance: 0,
    balanceUsd: 0,
    staked: 0,
    stakedUsd: 0,
    shares: 0,
    sharesPercent: 0,
};

function getProfileStats(user, currentPriceUsd, sharesTotalSupply) {
    const data = cloneDeep(base);

    if (user) {
        data.balance = parseFloat(user.balance);
        data.balanceUsd = data.balance * currentPriceUsd;
        data.activeBPD = parseFloat(user.activeBPD);
        data.activeBPDUsd = parseFloat(user.activeBPD) * currentPriceUsd;
        data.activeInterest = parseFloat(user.activeInterest);
        data.activeInterestUsd = parseFloat(data.activeInterest) * currentPriceUsd;
        data.totalBalance = data.balance + user.totalStaked + data.activeInterest + data.activeBPD;
        data.totalBalanceUsd = data.totalBalance * currentPriceUsd;
        data.totalBalanceUsdCents = `${data.totalBalanceUsd.toFixed(2)}`.split('.')[1];
        data.totalBalanceNoBPD = data.balance + user.totalStaked + data.activeInterest;
        data.totalBalanceNoBPDUsd = data.totalBalanceNoBPD * currentPriceUsd;
        data.totalBalanceUsdNoBPDUsdCents = `${data.totalBalanceNoBPDUsd.toFixed(2)}`.split('.')[1];
        data.staked = user.totalStaked;
        data.stakedUsd = data.staked * currentPriceUsd;
        data.shares = user.totalShares;
        data.sharesPercent = (data.shares / (sharesTotalSupply + 1)) * 100;
    }

    return data;
}

export default getProfileStats;
