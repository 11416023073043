import React from 'react';
import useStyles from './styles';
import { Typography, Tooltip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const SharedDivCheckbox = ({ checked, setChecked, token, divsInToken }) => {
    const classes = useStyles();

    return (
        <Tooltip title={`Use the ${divsInToken} ${token.symbol} you have earned by staking. You must use the full amount.`} arrow>
            <FormGroup>
                <FormControlLabel
                    style={{ padding: '0 5px' }}
                    control={<Checkbox className={classes.checkbox} onChange={setChecked} checked={checked} size="small" color="primary" />}
                    label={<Typography variant="caption">USE {token.symbol} DIVS</Typography>}
                />
            </FormGroup>
        </Tooltip>
    );
};

export default SharedDivCheckbox;
