import React from 'react';
import './orb.scss';
import useStyles from './styles';
import Typewriter from 'typewriter-effect';
import { Typography } from '@mui/material';

const lines = [
    'Collider powering up...',
    'Checking power levels...',
    'Sending particles...',
    'Burning NFT...',
    'Engaging 1.21 gigawatts...',
    'Cooling collider chamber...',
    'Scanning for AXN tokens...',
];

const ParticlesColliding = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className="scss_wrap">
                {new Array(300).fill(0).map((_, i) => (
                    <div key={i} className="scss_particle" />
                ))}
            </div>
            <div className="scss_content">
                <Typography className={classes.colliderEngagedText}>Collider Engaged!</Typography>
                <div className={classes.typeWriterContainer}>
                    <Typewriter
                        options={{
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            typewriter.changeDelay(75);
                            typewriter.changeDeleteSpeed(75);

                            lines.forEach((text, i) => {
                                typewriter.typeString(text);
                                if (i < lines.length - 1) typewriter.pauseFor(2500).deleteAll().pauseFor(500);
                            });
                            typewriter.start();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ParticlesColliding;
