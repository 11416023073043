import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    boxes: {
        margin: '0 auto',
        width: '55%',

        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    uniswap: {
        width: 50,
        height: 50,
        filter: theme.mode === 'supernova' ? 'sepia(50%)' : undefined,
    },
    atom: {
        width: 45,
        height: 45,
        marginBottom: theme.spacing(0.5),

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    lpb: {
        fontWeight: 500,
        color: theme.brand.JavaGreen,
    },
    plus: {
        fontSize: '1.75rem',
        '&:hover': {
            animation: 'rotate 1s ease-in-out',
        },
    },
    sharesGrid: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        padding: `16px 0`,

        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    pricesGrid: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        borderTop: `1px solid ${theme.brand.border}`,
    },
    bold: {
        fontWeight: 500,
    },
    amount: {
        marginTop: theme.spacing(2),
    },
    select: {
        marginTop: theme.spacing(1),
    },
    balance: {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(0.5),
    },
    maxLength: {
        marginTop: theme.spacing(0.5),
        fontWeight: 300,
    },
    selectMenu: {
        borderRadius: 5,
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, .15)',
    },
    container: {
        display: 'relative',
    },
    padding: {
        padding: theme.spacing(2),
    },
    stakeFooter: {
        left: 0,
        bottom: 0,
        width: '100%',
        position: 'absolute',
        borderTop: `1px solid ${theme.brand.border}`,
    },
    summaryTitle: {
        fontWeight: 500,
    },
    summarySub: {
        fontWeight: 200,
        fontSize: '0.8rem',
    },
    summaryReward: {
        fontWeight: 500,
        color: theme.brand.JavaGreen,
    },
    accelerator: {
        fontWeight: 500,
    },
    basicStat: {
        fontSize: '1.25rem',
    },
    stat: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
    },
    statSubtitle: {
        fontSize: '0.9rem',
        fontWeight: 300,
    },
    next: {
        display: 'flex',
        alignItems: 'center',
    },
    nextIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1.5rem',
    },
    finishIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1rem',
        '&:hover': {
            color: theme.brand.primary,
        },
    },
    backIcon: {
        marginRight: theme.spacing(0.5),
        fontSize: '1.5rem',
    },
    approvalIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1.2rem',
    },
    approveImage: {
        opacity: 0.7,
        width: '35%',
        userSelect: 'none',
        padding: theme.spacing(2),
    },
    approvalContainer: {
        width: '80%',
        margin: '0 auto',
    },
    prices: {
        marginTop: theme.spacing(5),
        textAlign: 'center',
    },
    borderRight: {
        borderRight: `1px solid ${theme.brand.border}`,
    },
    borderTop: {
        borderTop: `1px solid ${theme.brand.border}`,
    },
    bonusText: {
        fontWeight: 500,
        color: theme.brand.JavaGreen,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    slide: {
        height: '100%',
        overflow: 'hidden',
    },
    date: {
        textAlign: 'center',
        fontSize: '1.25rem',
    },
    dateContainer: {
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
        },
    },
    summaryLogo: {
        paddingRight: theme.spacing(1),
        width: 15,
    },
    infoIcon: {
        fontSize: '0.8rem',
        cursor: 'pointer',
    },
    lpbStat: {
        display: 'flex',
        alignItems: 'center',
    },
    iconsContainer: {
        marginBottom: theme.spacing(2),
    },
}));

export default useStyles;
