import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    portfolioTitle: {
        padding: '1.5rem',
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    portfolioValue: {
        padding: '50px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.brand.border}`,
        color: theme.brand.NormalText,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    dollarSign: {
        fontSize: 32,
        fontFamily: '"Heebo", sans-serif',
    },
    valueDollars: {
        fontSize: 40,
        fontWeight: 'bold',
        fontFamily: '"Heebo", sans-serif',
    },
    valueCents: {
        display: 'block',
        fontSize: 24,
        marginTop: -10,
        fontFamily: '"Heebo", sans-serif',
    },
    portfolioStats: {
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        padding: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& li': {
            borderBottom: `1px solid ${theme.brand.border}`,
            paddingBottom: 0,
        },
        '& li:last-child': {
            borderBottom: `none`,
        },
    },
    listItem: {
        flex: 1,
        padding: 0,
        '& > div:first-child': {
            padding: `11px 16px`,
        },
        '& > div:last-child': {
            textAlign: 'right',
        },
    },
    listItemRoot: {
        height: '100%',
    },
    portfolioFooter: {
        padding: '30.5px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    primary: {
        color: theme.brand.SecondaryText,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
        },
    },
    secondary: {
        fontWeight: 300,
        [theme.breakpoints.only('lg')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
    },
    // Stuf
    bgBlueShade1: {
        backgroundColor: theme.brand.BPDBlueShade4,
        color: 'white',
        boxShadow: theme._shadows.icon,
    },
    bgBlueShade2: {
        backgroundColor: theme.brand.BPDBlueShade2,
        color: 'white',
        boxShadow: theme._shadows.icon,
        [theme.breakpoints.down('sm')]: {
            width: 30,
            height: 30,
        },
    },
    bgWhite: {
        backgroundColor: 'white',
        color: 'white',
        boxShadow: theme._shadows.icon,
    },
    bgAxn: {
        backgroundColor: theme.brand.axion,
        color: 'white',
        boxShadow: theme._shadows.icon,
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    logo: {
        height: '65%',
    },
    mt6: {
        marginTop: 6,
    },
    icon: {
        fontSize: '1.5em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    iconSmall: {
        cursor: 'pointer',
        fontSize: '1em',
    },
    disclaimerContainer: {
        borderTop: `1px solid ${theme.brand.border}`,
    },
    disclaimer: {
        fontSize: '0.7rem',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    wbtcDivsMain: {
        fontWeight: 500,
        fontSize: '1.1rem',
        color: theme.brand.SecondaryText,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    wbtcDivsSub: {
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7em',
        },
    },
}));

export default useStyles;
