import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Slider, Typography } from '@mui/material';

const SharedSlider = ({ minStakeDays, days, changeSlider, paddingBottom }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.sliderContainer, classes.borderBottom)}>
            <Slider
                step={1}
                max={5555}
                min={minStakeDays}
                value={+days.value || 1}
                valueLabelDisplay="auto"
                style={{ paddingBottom }}
                className={classes.slider}
                onChange={(_, value) => changeSlider(value)}
                marks={[
                    { value: minStakeDays, label: <Typography variant="h6">{minStakeDays} Days</Typography> },
                    { value: 1825, label: <Typography variant="h6">5 Years</Typography> },
                    { value: 3650, label: <Typography variant="h6">10 Years</Typography> },
                    { value: 5555, label: <Typography variant="h6">15 Years</Typography> },
                ]}
            />
        </div>
    );
};

export default SharedSlider;
