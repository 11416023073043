import React from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { GALAXY_INFO_URL } from 'utils/variables';

const MintNFT = () => {
    const classes = useStyles();

    const openKB = () => {
        window.open(GALAXY_INFO_URL, '_blank');
    };

    return (
        <div>
            <Typography>Convert your stake to a unique Galaxy NFT!</Typography>
            <br />
            <Typography>
                By doing so, your stake will remain active with the same parameters (start/end dates, principal &amp; shares), and will
                continue to earn you interest and rewards, but it will now be represented by a unique galaxy NFT depending on the size and
                duration of your stake.
            </Typography>
            <br />
            <Typography>
                Once your stake is minted as an NFT, it can then be transferred from wallet to wallet, allowing you to move all of your
                stakes into a single wallet or hardware wallet, or sell your stake as an NFT via Opensea.
            </Typography>
            <br />
            <Typography className={classes.link} onClick={openKB}>
                For more information please click here.
            </Typography>
        </div>
    );
};

export default MintNFT;
