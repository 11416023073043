import React from 'react';
import useStyles from './styles';
import Button from 'components/Button';
import NumberFormatField from 'components/NumberFormatField';
import { InputAdornment, TextField, Tooltip } from '@mui/material';

const SharedAmount = ({ amount, balance, tokenPrice, disabled, handleAmount, handleMaxAmount }) => {
    const classes = useStyles();

    return (
        <Tooltip arrow placement="top" title={`$${((+amount.value || 0) * tokenPrice).numberWithCommas(2)}`}>
            <TextField
                fullWidth
                name="amount"
                variant="outlined"
                disabled={disabled}
                value={amount.value}
                error={!amount.valid}
                label="Amount to Spend"
                onChange={handleAmount}
                InputLabelProps={{ shrink: !amount.value ? false : true }}
                InputProps={{
                    className: classes.textfield,
                    inputComponent: NumberFormatField,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button disabled={disabled || balance === 0} onClick={handleMaxAmount}>
                                Send Max
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
        </Tooltip>
    );
};

export default SharedAmount;
