import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Grid, Typography } from '@mui/material';
import AxionAccelerator from 'assets/images/AxionAccelerator.svg';
import AxionAcceleratorSupernova from 'assets/images/AxionAccelerator_Supernova.svg';
import Countdown from './Countdown';
import ArcReactor from './ArcReactor';
import { useSelector } from 'react-redux';
import { MAX_APR } from 'utils/variables';
import { useNavigate } from 'react-router';

const PageTitle = ({ bonusInfo }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { theme } = useSelector((store) => store);

    const [progress, setProgress] = useState(0);
    const [doneAnim, setDoneAnim] = useState(false);

    useEffect(() => {
        let progress = 0;
        const percentSold = ((bonusInfo.max - bonusInfo.remaining) / bonusInfo.max) * 100;

        setTimeout(() => {
            let interval = setInterval(() => {
                if (progress <= percentSold) setProgress(progress++);
                else {
                    setDoneAnim(true);
                    clearInterval(interval);
                }
            }, 10);

            return () => clearInterval(interval);
        }, 1000);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (doneAnim) {
            setProgress(Math.round(((bonusInfo.max - bonusInfo.remaining) / bonusInfo.max) * 100));
        }

        // eslint-disable-next-line
    }, [bonusInfo]);

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container alignItems="center" justify="space-between" spacing={2}>
                    <Grid item md={6} xs={12}>
                        {theme.mode === 'supernova' ? (
                            <img
                                src={AxionAcceleratorSupernova}
                                className={clsx(classes.accelerateImage, classes.supernovaGlow)}
                                alt="accelerator logo"
                            />
                        ) : (
                            <img src={AxionAccelerator} className={classes.accelerateImage} alt="accelerator logo" />
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography
                            className={clsx(classes.accelerate, {
                                [classes.supernovaGlow]: theme.mode === 'supernova',
                            })}
                        >
                            Accelerate your Crypto.
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.rootRow2}>
                <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }} spacing={2}>
                    <Grid item lg={5} xs={12}>
                        <Typography
                            className={clsx(classes.subtitle, {
                                [classes.supernovaGlow]: theme.mode === 'supernova',
                            })}
                        >
                            Buy &amp; Stake <b>in one transaction</b>!
                            <br />
                            Earn up to <b>{MAX_APR}% APR</b> &amp; daily liquid Bitcoin.
                            <br />
                            Get up to a 10x multiplier NFT <span className={classes.colliderText}>for the Collider!</span>
                        </Typography>

                        <div
                            className={clsx(classes.newToAxionButton, {
                                [classes.supernovaGlow]: theme.mode === 'supernova',
                            })}
                            onClick={() => window.open('https://axion.network/how-to-buy-axion')}
                        >
                            <Typography className={classes.newToAxionText}>
                                New to Axion? <b>Click here!</b>
                            </Typography>
                        </div>
                        <div
                            className={clsx(classes.newToAxionButton, classes.colliderButton, {
                                [classes.supernovaGlow]: theme.mode === 'supernova',
                            })}
                            onClick={() => navigate('/collider')}
                        >
                            <Typography className={classes.newToAxionText}>
                                Engage the <b>Collider</b>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container lg={7} xs={12} sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <Grid item>
                            <ArcReactor progress={progress} soldOut={!bonusInfo.active} end={bonusInfo.endSeconds} />
                        </Grid>
                        <Grid item className={classes.center}>
                            <Typography className={classes.heading}>
                                Accelerator <span className={classes.bold}>Bonus</span>
                                <span className={classes.bonusSubheading}>Up to 20.8% additional AXN on your principal</span>
                            </Typography>
                            <Typography className={classes.subHeading}>Bonus Recharges In</Typography>
                            <Typography className={classes.stat}>
                                {bonusInfo.endSeconds ? <Countdown end={bonusInfo.endSeconds * 1000} /> : '0h 0m 0s'}
                            </Typography>
                            <Typography className={classes.subHeading}>Bonus Remaining</Typography>
                            <Typography className={classes.stat}>{bonusInfo?.remaining?.numberWithCommas(0)} AXN</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default PageTitle;
