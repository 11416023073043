import React from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';

const Withdraw = ({ token, data }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography>
                Withdraw all of the {token?.tokenSymbol} rewards you have earned ({data?.interestEarnedToken.toFixed(8)}) directly to your
                wallet. If you do not see the tokens after withdrawal, you must add the following contract address to your wallet:{' '}
                {token?.tokenAddress}
            </Typography>
            {token?.tokenSymbol === 'WBTC' && (
                <Typography>
                    <br />
                    WARNING &ndash; Once withdrawn to your normal wallet, please do not send your wBTC to Coinbase or other exchanges which
                    do not support Polygon network. We recommend first converting your wBTC to either USDC or MATIC, then sending it to
                    Crypto.com or Kucoin, but you must carefully select the deposit address for Polygon, not Ethereum. You will lose your
                    funds if you send them to an address that does not support Polygon deposits.
                </Typography>
            )}
        </div>
    );
};

export default Withdraw;
