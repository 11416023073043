import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
    },
    img: {
        height: 40,
        width: 40,
    },
    imgBTC: {
        filter: theme.mode === 'supernova' ? 'sepia(20%) saturate(3000%) brightness(95%)' : undefined,
    },
    tokenImg: {
        height: 40,
        width: 40,
        marginBottom: 6,
        filter: theme.mode === 'supernova' ? 'sepia(20%) saturate(3000%) brightness(95%)' : undefined,
    },
    boughtGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    summaryTitle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',

        [theme.breakpoints.down('xs')]: {
            whiteSpace: 'normal',
        },
    },
    summarySub: {
        fontWeight: 200,
        fontSize: '0.8rem',
    },
    tokenGrid: {
        borderTop: `1px solid ${theme.brand.border}`,
        paddingTop: theme.spacing(2),
        flexWrap: 'wrap',
    },
    token: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    link: {
        cursor: 'pointer',
    },
}));

export default useStyles;
