import React, { useContext, useEffect, useState } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Card } from '@mui/material';
import particles from './particles.json';
import { useDispatch } from 'react-redux';
import Pipes from 'assets/images/Pipes.svg';
import ColliderSettings from './ColliderSettings';
import DepositParticles from './DepositParticles';
import SwipeableViews from 'react-swipeable-views';
import { ContractContext } from 'web3/WagmiListener';
import ParticlesCollided from './ParticlesCollided';
import { setSnackbar } from 'redux/actions/snackbar';
import ParticlesColliding from './ParticlesColliding';
import { ANIMATION_KEY } from 'containers/Accelerator/variables';
import AxionSpinnyCircle from 'assets/images/AxionSpinnyCircle.svg';
import { addTx, completedTx } from 'redux/actions/transactions';
import { ethers } from 'ethers';

const ParticleCollider = ({ nft, setNFT, colliding, setColliding, collided, setCollided }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { library, getWalletDetails } = useContext(ContractContext);

    const [index, setIndex] = useState(0);
    const [preTX, setPreTX] = useState(false);
    const [success, setSuccess] = useState(false);
    const [days, setDays] = useState({ value: '365', valid: true });

    useEffect(() => {
        if (index !== 0) setIndex(0);
        if (nft) {
            if (!days.value) setDays({ value: '5555', valid: true });
        } else setDays({ value: '365', valid: true });

        // eslint-disable-next-line
    }, [nft]);

    const handleCollide = async () => {
        setPreTX(true);
        let startTime;

        // Using the returned PromiEvent to better control the tx lifecycle
        let tx;
        try {
            tx = await library.NFT.redeem(nft.metadata.id, days.value);
            dispatch(addTx({ id: tx.hash, description: `Axion Collider Redemption` }));
            startTime = new Date();
            setColliding(true);
            setIndex(1);

            const receipt = await tx.wait();
            setIndex(2);
            setCollided(true);
            getWalletDetails();
            setColliding(false);
            const event = receipt.events.find((ev) => ev.event === 'Redeemed');
            setSuccess(event?.args?.succeeded ?? false);

            // const _getEnd = async () => {
            // };
            // _getEnd();
            // const onReceipt = async () => {
            //     // see if 50 seconds has passed since startTime
            //     const seconds = 50;
            //     const endTime = new Date();
            //     const timeDiff = endTime - startTime;
            //     if (timeDiff > seconds * 1000) _getEnd();
            //     else {
            //         const interval = setInterval(() => {
            //             const endTime = new Date();
            //             const timeDiff = endTime - startTime;
            //             if (timeDiff > seconds * 1000) {
            //                 _getEnd();
            //                 clearInterval(interval);
            //             }
            //         }, 5000);
            //     }
            // };

            // onReceipt();
        } catch (error) {
            console.error({ error });

            if (error?.code === 4001) {
                setIndex(0);
                dispatch(setSnackbar({ message: error.message }));
            }
        }
        dispatch(completedTx(tx.hash));
        setPreTX(false);
        setColliding(false);
    };

    const resetCollider = () => {
        setNFT(null);
        setIndex(0);
        setPreTX(false);
        setCollided(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div id="particles-js-collider" className={clsx(classes.particles, { [classes.hidden]: !colliding })} />
            <img
                src={AxionSpinnyCircle}
                alt="background circle"
                className={clsx(classes.spinny, {
                    [classes.off]: !nft,
                    [classes.fastRotate]: colliding,
                    [classes.slowRotate]: !colliding && nft && !collided,
                })}
            />

            <div className={clsx(classes.collider, { [classes.pulsate]: nft })}>
                <div className={clsx(classes.colliderItem, classes.hideSmall)}>
                    <DepositParticles setNFT={setNFT} nft={nft} colliding={colliding} collided={collided} />
                </div>

                <div className={clsx(classes.colliderItem, classes.hideSmall, classes.flexPipes)}>
                    <img
                        src={Pipes}
                        alt="Pipes"
                        className={clsx(classes.pipes, {
                            [classes.pipesGlow]: nft && !collided,
                            [classes.pipesPulsate]: colliding,
                        })}
                    />
                </div>

                <div className={clsx(classes.colliderItem, classes.flex)}>
                    <Card
                        className={clsx(classes.card, {
                            [classes.greenBorder]: collided && success,
                            [classes.redBorder]: collided && !success,
                        })}
                    >
                        <SwipeableViews
                            disabled
                            index={index}
                            ignoreNativeScroll
                            animateHeight={true} // below, fixes first transition issue
                            containerStyle={{
                                // height: 'auto !important',
                                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
                            }}
                        >
                            <div className={classes.settings}>
                                <ColliderSettings
                                    nft={nft}
                                    days={days}
                                    preTX={preTX}
                                    setDays={setDays}
                                    colliding={colliding}
                                    handleCollide={handleCollide}
                                />
                            </div>
                            <div className={classes.colliding}>{colliding && <ParticlesColliding />}</div>
                            <div className={classes.collisionResult}>
                                <ParticlesCollided success={success} reset={resetCollider} />
                            </div>
                        </SwipeableViews>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default ParticleCollider;
