import React, { useContext, useMemo } from 'react';
import clsx from 'classnames';
import Card from 'components/Card';
import { DEX, MAX_APR } from 'utils/variables';
import { useNavigate } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { DISCOUNT, MAX_BONUS } from 'containers/Accelerator/variables';

// Assets
import useStyles from './styles';
import { BiRocket } from 'react-icons/bi';
import { AiOutlineBank } from 'react-icons/ai';
import DEXLogo from 'assets/images/apeeswaplogo.png';
import AnimatedAtom from 'components/AnimatedAtom/AnimatedAtom';
import { ContractContext } from 'web3/WagmiListener';

const InfoButton = ({ title, mainStat, subStat, notes, img, Icon, gradient, url, white, ...props }) => {
    const classes = useStyles(props);
    const navigate = useNavigate();

    return (
        <Card
            glossy
            button
            onClick={() => navigate(url)}
            classes={{
                root: clsx(classes.root, {
                    [classes.primary]: true,
                    [classes.supernovaGlow]: white,
                    [classes.bgUniswap]: gradient === 'uniswap',
                    [classes.bgBlueteal]: gradient === 'blue-teal',
                    [classes.primary]: gradient === 'primary',
                    [classes.acceleratorBG]: gradient === 'accelerator',
                }),
            }}
        >
            <Grid container alignItems="center">
                <Grid item>
                    <div className={classes.statIconView}>
                        {img && <img src={img} alt={title} className={classes.statIcon} color="primary" />}
                        {Icon && <Icon src={img} className={clsx(classes.statIcon, { [classes.white]: white })} />}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <span className={clsx(classes.statCardTitle, { [classes.white]: white === true })}>{title}</span>
                    <div className={clsx(classes.statCardNote, { [classes.white]: white === true })}>{notes}</div>
                </Grid>
            </Grid>

            {mainStat && (
                <div className={classes.info}>
                    <Typography className={classes.mainStat}>{mainStat}</Typography>
                    {subStat && <Typography className={classes.subStat}>{subStat}</Typography>}
                </div>
            )}
        </Card>
    );
};

const InfoButtons = () => {
    const { contractInfo, loadingContractInfo } = useContext(ContractContext);

    const items = useMemo(() => {
        let priceETH = 0;
        let priceUSD = 0;

        if (!loadingContractInfo && contractInfo?.axion) {
            priceETH = contractInfo?.axion?.axnPerETH;
            priceUSD = contractInfo?.axion?.usdPerAXN;
        }

        return [
            {
                white: true,
                url: '/accelerator',
                icon: AnimatedAtom,
                gradient: 'accelerator',
                title: 'Buy in Accelerator',
                notes: 'Enjoy AXN at a significantly discounted rate by purchasing from the Axion Accelerator.',
                subStat: `1 ETH gets you up to ${(priceETH * DISCOUNT * MAX_BONUS).numberWithCommas(0)} AXN`,
                mainStat: `Accelerator Price: ${(priceUSD - priceUSD * (DISCOUNT - 1)).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 6,
                })}`,
            },
            {
                white: true,
                url: '/buy',
                gradient: 'primary',
                image: DEXLogo,
                title: 'Buy from the Market',
                notes: `Buy or sell AXN directly on ${DEX}, a decentralized crypto trading protocol on Polygon.`,
                subStat: `1 ETH gets you up to ${priceETH.numberWithCommas(0)} AXN`,
                mainStat: `${DEX} Price: ${priceUSD.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'usd',
                    minimumFractionDigits: 6,
                })}`,
            },
            {
                url: '/stake',
                title: 'Stake Your Axion',
                notes: `Earn up to ${MAX_APR}% APR including liquid bitcoin rewards.`,
                icon: AiOutlineBank,
            },
            {
                url: '/launch',
                title: 'Axion Launch',
                notes: 'Take part in exclusive launches and airdrops.',
                icon: BiRocket,
            },
        ];
    }, [contractInfo, loadingContractInfo]);

    return (
        <Grid container spacing={2}>
            {items.map((i, idx) => (
                <Grid item lg={6} xs={12} key={`button_${idx}`} className="emphasize" style={{ animationDelay: `${(idx + 2) / 2}s` }}>
                    <InfoButton
                        url={i.url}
                        img={i?.image}
                        Icon={i?.icon}
                        body={i?.body}
                        notes={i.notes}
                        title={i.title}
                        white={i?.white}
                        subStat={i?.subStat}
                        gradient={i.gradient}
                        mainStat={i?.mainStat}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default InfoButtons;
