import React, { useState } from 'react';
import useStyles from './styles';
import SlideUp from 'components/Transitions/SlideUp';

import Button from 'components/Button';
import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from '@mui/material';

const DisclaimerModal = ({ open, onClose, data }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);

    function handleConfirm() {
        localStorage.setItem(data.key, Date.now());
        onClose();
    }

    return (
        <Dialog
            open={open}
            scroll="paper"
            onClose={() => {
                return false;
            }}
            disableBackdropClick
            disableEscapeKeyDown
            classes={{ paper: classes.paper, root: classes.root }}
            TransitionComponent={SlideUp}
        >
            <div className={classes.dialogTitle}>
                <InfoOutlined className={classes.icon} />
                <Typography variant="h2">{data.title}</Typography>
            </div>

            <DialogContent className={classes.contentContainer}>
                <Typography variant="body1">
                    {data.subtitle && (
                        <>
                            {data.subtitle}
                            <br />
                            <br />
                        </>
                    )}
                    {data.explain.map((e, i) => (
                        <div key={`explain-${i}`} className={i > 0 && classes.explainItem}>
                            {e}
                        </div>
                    ))}
                </Typography>
                <br />

                {data.extraInfo.length > 0 && (
                    <>
                        <Typography variant="h4">{data.extraInfoSubtitle}</Typography>
                        <Typography variant="body1">
                            <ul>
                                {data.extraInfo.map((i, index) => (
                                    <React.Fragment key={`key-${index}`}>{i}</React.Fragment>
                                ))}
                            </ul>
                        </Typography>
                    </>
                )}

                {data.warnings.length > 0 && (
                    <>
                        <Typography variant="h3">Warnings</Typography>
                        {data.warnings.map((warning, index) => (
                            <React.Fragment key={`warning ${index}`}>
                                <Typography variant="body1">{warning}</Typography>
                                <br />
                            </React.Fragment>
                        ))}
                    </>
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={(ev) => setChecked(ev.target.checked)} color="secondary" />}
                    label="I understand"
                />
                <Button onClick={handleConfirm} color="secondary" size="large" disabled={!checked}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisclaimerModal;
