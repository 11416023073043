import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme._shadows.card,
        backgroundColor: theme.brand.CardBG,
        borderRadius: 4,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    titleIcon: {
        marginRight: theme.spacing(1),
    },
    disabled: {
        opacity: 0.1,
    },
    buttons: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
    },
    checkbox: {
        marginTop: theme.spacing(2),
        padding: `8px 16px`,
        borderTop: `1px solid ${theme.brand.border}`,
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    serviceFee: {
        fontSize: '0.9rem',
        fontWeight: 300,
    },
    serviceFeeBold: {
        fontWeight: 500,
    },
}));

export default useStyles;
