import { makeStyles } from '@mui/styles';
import font from 'assets/fonts/Retro-Computer.ttf.woff';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
    },
    resultText: {
        fontWeight: 500,
        fontSize: '1.35rem',
        textAlign: 'center',
        fontFamily: `Retro Computer`,
        paddingBottom: theme.spacing(2),
    },
    colliderResultImage: {
        width: '45%',
        display: 'block',
        margin: '0 auto',
    },
    textContainer: {
        fontWeight: 300,
        fontSize: '1rem',
        letterSpacing: '0.2em',
        marginTop: theme.spacing(2),
    },
    resultSubText: {
        fontSize: '1.1rem',
        fontWeight: 300,
    },
    greenText: {
        color: theme.brand.JavaGreen,
    },
    redText: {
        color: theme.brand.error,
    },
    resetButton: {
        marginTop: theme.spacing(4),
        border: `5px solid ${theme.brand.border}`,
        backgroundColor: theme.brand.colliderOrange,
        '&:hover': {
            filter: 'brightness(120%)',
            backgroundColor: theme.brand.colliderOrange,
        },
    },
    goToStakeButton: {
        marginTop: theme.spacing(4),
        border: `5px solid ${theme.brand.border}`,
        backgroundColor: theme.brand.colliderBlue,
        '&:hover': {
            filter: 'brightness(120%)',
            backgroundColor: theme.brand.colliderBlue,
        },
    },
    '@font-face': {
        fontFamily: 'Retro Computer',
        src: `url(${font})`,
    },
}));

export default useStyles;
