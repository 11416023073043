import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    /** Stat stuff */
    bgBlueteal: {
        // backgroundColor: theme.brand.RoyalBlue,
        background: theme._gradients.blueTeal,
        color: 'white',
    },
    bgUniswap: {
        background: theme._gradients.uniswap,
        color: 'white',
    },
    bgDark: {
        background: theme._gradients.dark,
        color: 'white',
    },

    primary: {
        background: `${theme.brand.axion}9e`,
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    secondary: {
        background: `${theme.brand.accent}9e`,
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    root: {
        padding: 24,
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    statCardTitle: {
        fontSize: 20,
        color: 'white',
        fontWeight: 700,
        fontFamily: '"Heebo", sans-serif',
    },
    statCardValue: {
        display: 'block',
        fontSize: 20,
        color: 'white',
        fontWeight: 700,
        fontFamily: '"Heebo", sans-serif',
        textAlign: 'center',
        padding: '16px 0px',
    },
    statCardNote: {
        display: 'block',
        fontSize: 16,
        color: 'white',
        fontWeight: 500,
        fontFamily: '"Heebo", sans-serif',
        textAlign: 'center',
    },
    statIconView: {
        marginRight: 12,
        padding: 6,
        borderRadius: '50%',
    },
    statIcon: {
        color: 'white',
        height: 40,
        width: 40,
    },
    statIconImage: {
        height: 40,
    },
}));

export default useStyles;
