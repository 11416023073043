import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Button, Grid, Typography } from '@mui/material';
import ParticleFail from 'assets/images/ParticleFail.jpg';
import ParticleSuccess from 'assets/images/ParticleSuccess.jpg';
import { useNavigate } from 'react-router';

const ParticlesCollided = ({ success, reset }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <img src={success ? ParticleSuccess : ParticleFail} className={classes.colliderResultImage} alt="collider result" />
            <div className={classes.container}>
                <Typography
                    className={clsx(classes.resultText, {
                        [classes.greenText]: success,
                        [classes.redText]: !success,
                    })}
                >
                    {success ? 'Collision Successful!' : 'Collision Failed'}
                </Typography>
                <Typography className={classes.resultSubText}>
                    {success ? (
                        <span>
                            Your particles successfully fused into AXN!
                            <br />
                            You can now find your new stake on the stake page.
                        </span>
                    ) : (
                        <span>
                            The particles did not collide and create new AXN.
                            <br />
                            Try again with another NFT!
                        </span>
                    )}
                </Typography>

                <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={success ? 6 : 12}>
                        <Button fullWidth variant="contained" onClick={() => reset()} className={classes.resetButton}>
                            Reset the Collider
                        </Button>
                    </Grid>

                    {success && (
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.goToStakeButton}
                                onClick={() => navigate('/stake', { scroll: true })}
                            >
                                Visit Stake Page
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default ParticlesCollided;
