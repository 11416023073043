import React from 'react';
import { Typography } from '@mui/material';

const WithdrawStake = ({ data }) => {
    return (
        <div>
            <Typography>
                Receive liquid AXN and complete your stake. Any shares that belonged to this stake will be burned, and in return, you will
                receive your principal amount along with any interest you earned during the stake.
            </Typography>

            {data?.isLate && +data?.penalty > 0 && (
                <>
                    <br />
                    <Typography>
                        Since you are withdrawing this stake late, a penalty of {data?.penalty?.numberWithCommas(0)} AXN will be taken from
                        your stake.
                    </Typography>
                </>
            )}
        </div>
    );
};

export default WithdrawStake;
