import React from 'react';
// Material UI
import { withStyles } from '@mui/styles';
import { ListItem, ListItemText } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
// styles
import styles from './styles';

const SkeletonListItem = ({ classes, ...props }) => {
    return (
        <ListItem className={classes.listItem}>
            <ListItemText primary={<Skeleton variant="rect" />} secondary={<Skeleton variant="rect" />} />
        </ListItem>
    );
};

export default withStyles(styles)(SkeletonListItem);
