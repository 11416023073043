import React, { useState, useEffect } from 'react';
// Moduels
import moment from 'moment';
// Material UI
import { cloneDeep } from 'lodash';
// Components
import AxnValueTooltip from 'components/AxnValueTooltip';
import { Tooltip } from '@mui/material';
import CustomTable from 'components/CustomTable';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
// Hooks
// styles
import useStyles from '../styles';

const TableHeaders = ['Name', 'Start', 'End Date', 'Principal', 'Shares', 'Interest/Penalty', 'APR', 'Payout'];

const HistoryTable = ({ title, data = [], ...props }) => {
    const mounted = React.useRef(false);
    const classes = useStyles(props);
    const [stakes, setStakes] = useState([]);
    // Pagination
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState({
        order: 'asc',
        orderBy: 'Start',
    });

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        let ordered;
        const clonedStakes = cloneDeep(data);
        if (sort.orderBy === 'Interest/Penalty') {
            ordered = clonedStakes.sort((a, b) => {
                const interestA = parseFloat(a.interest) + parseFloat(a.bigPayDay);
                const interestB = parseFloat(b.interest) + parseFloat(b.bigPayDay);
                return sort.order === 'asc' ? interestB - interestA : interestA - interestB;
            });
            setStakes(ordered);
        } else {
            let translation = {
                Name: 'name',
                'Start Date': 'start',
                'End Date': 'end',
                Progress: 'percent',
                Principal: 'principal',
                Shares: 'shares',
                Interest: 'interest',
                Total: 'total',
                Payout: 'payout',
            };
            const key = translation[sort.orderBy];
            ordered = clonedStakes.sort((a, b) => {
                if (key === 'start' || key === 'end') {
                    const dateA = new Date(a[key]).getTime();
                    const dateB = new Date(b[key]).getTime();
                    return sort.order === 'asc' ? dateB - dateA : dateA - dateB;
                } else return sort.order === 'asc' ? b[key] - a[key] : a[key] - b[key];
            });
            setStakes(ordered);
        }

        setStakes(ordered.splice(perPage * page, perPage));
    }, [data, sort, page, perPage]);

    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }
    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }
    function handleOnSort(newOrder, index) {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    }

    return (
        <CustomTable
            classes={{ root: classes.table }}
            headers={TableHeaders}
            count={data.length}
            page={page}
            onSort={handleOnSort}
            onChangePage={handleChangePage}
            onChangeRows={handleChangeRows}
            initialOrderIndex={0}
            rowsPerPage={perPage}
            rows={stakes.map((item, index) => {
                if (!item)
                    return {
                        columns: SkeletonTableRow({
                            count: 3,
                        }),
                    };

                return {
                    columns: [
                        item.name,
                        moment(item.start).format('MMM DD, YYYY'),
                        <Tooltip arrow title={moment(item.end).format('MMM DD, YYYY hh:mm:ss a')}>
                            <span>{moment(item.end).format('MMM DD, YYYY')}</span>
                        </Tooltip>,
                        <AxnValueTooltip value={item.principal} />,
                        item.shares.numberWithCommas(0),
                        <AxnValueTooltip value={item.interest} />,
                        `${item.apy.toFixed(2)}%`,
                        item.payout.numberWithCommas(),
                    ],
                };
            })}
        />
    );
};

export default HistoryTable;
