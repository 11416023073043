import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import Withdraw from './Withdraw/Withdraw';
import Button from 'components/Button/Button';
import { ContractContext } from 'web3/WagmiListener';
import SlideUp from 'components/Transitions/SlideUp';
import { setSnackbar } from 'redux/actions/snackbar';
import { CheckCircleOutline } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, useTheme } from '@mui/material';

const WithdrawDivsModal = ({ open, data, token, onClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { web3, library, getWalletDetails } = useContext(ContractContext);

    const [tab, setTab] = useState(0);
    const [divs, setDivs] = useState(null);
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [validAddress, setValidAddress] = useState(true);

    useEffect(() => {
        if (data && token) {
            setDivs(data?.find((d) => d.tokenAddress === token?.tokenAddress));
        }
    }, [data, token]);

    const tabData = useMemo(() => {
        const _handleAction = async (address) => {
            setLoading(true);

            try {
                if (address) await library.Staking.withdrawDivForTokenTo(token.tokenAddress, address);
                else await library.Staking.withdrawDivForToken(token.tokenAddress);

                handleClose();
                getWalletDetails();
            } catch (error) {
                dispatch(setSnackbar({ message: error.message }));
            } finally {
                setLoading(false);
            }
        };

        try {
            const addr = web3.utils.toChecksumAddress(address);

            setAddress(addr);
            setValidAddress(web3.utils.isAddress(addr));
        } catch {
            setValidAddress(false);
        }

        switch (tab) {
            case 0:
                return {
                    action: 'Withdraw',
                    handle: () => _handleAction(),
                };
            case 1:
                return {
                    action: 'Withdraw External',
                    disabled: !validAddress,
                    handle: () => _handleAction(address),
                };
            default:
                return {
                    action: 'Withdraw',
                    handle: () => _handleAction(),
                };
        }

        // eslint-disable-next-line
    }, [tab, address, token, validAddress]);

    const handleClose = () => {
        setTab(0);
        setAddress('');
        setLoading(false);
        onClose();
    };

    return (
        <Dialog
            scroll={'body'}
            maxWidth={'md'}
            open={open === true}
            onClose={handleClose}
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                <Typography variant="h3" className={classes.title}>
                    <CheckCircleOutline className={classes.titleIcon} />
                    Withdraw Rewards
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Withdraw token={token} data={divs} />
            </DialogContent>

            <DialogActions>
                <div className={classes.buttons}>
                    <Button fullWidth color="primary" variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        fullWidth
                        color="primary"
                        loading={loading}
                        variant="contained"
                        onClick={tabData.handle}
                        disabled={tabData.disabled || loading}
                        override={theme.mode === 'dark' ? 'blue' : 'supernova'}
                    >
                        {tabData.action}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default WithdrawDivsModal;
