import React, { useState, useEffect } from 'react';

// Moduels
import moment from 'moment';
import useStyles from '../styles';
import { cloneDeep } from 'lodash';

// Components
import Button from 'components/Button';
import { Tooltip } from '@mui/material';
import CustomTable from 'components/CustomTable';
import AxnValueTooltip from 'components/AxnValueTooltip';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import { BiPencil } from 'react-icons/bi';

const TableHeaders = ['Name', 'Start', 'End', 'Progress', 'Principal', 'Shares', 'Interest', 'Total BPD', 'APR', ''];
const translation = {
    Name: 'name',
    Start: 'start',
    End: 'end',
    Progress: 'percent',
    Principal: 'principal',
    Shares: 'shares',
    'Total BPD': 'bigPayDay',
    Interest: 'interest',
    Total: 'total',
    APR: 'apy',
};

const ActiveMaturedTable = ({ data = [], ...props }) => {
    const classes = useStyles(props);

    const [page, setPage] = useState(0);
    const [stakes, setStakes] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ order: 'asc', orderBy: 'Start' });

    useEffect(() => {
        let ordered;
        const clonedStakes = cloneDeep(data);
        if (sort.orderBy === 'Name') {
            ordered = clonedStakes.sort((a, b) => {
                return sort.order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            });
        }
        if (sort.orderBy === 'Total') {
            ordered = clonedStakes.sort((a, b) => {
                const totalA = parseFloat(a.principal) + parseFloat(a.interest);
                const totalB = parseFloat(b.principal) + parseFloat(b.interest);
                return sort.order === 'asc' ? totalB - totalA : totalA - totalB;
            });
        } else if (sort.orderBy === 'Interest') {
            ordered = clonedStakes.sort((a, b) => {
                const interestA = parseFloat(a.interest) + parseFloat(a.bigPayDay);
                const interestB = parseFloat(b.interest) + parseFloat(b.bigPayDay);
                return sort.order === 'asc' ? interestB - interestA : interestA - interestB;
            });
        } else {
            const key = translation[sort.orderBy];
            ordered = clonedStakes.sort((a, b) => {
                if (key === 'start' || key === 'end') {
                    const dateA = new Date(a[key]).getTime();
                    const dateB = new Date(b[key]).getTime();
                    return sort.order === 'asc' ? dateB - dateA : dateA - dateB;
                } else return sort.order === 'asc' ? b[key] - a[key] : a[key] - b[key];
            });
        }

        setStakes(ordered.splice(perPage * page, perPage));

        // eslint-disable-next-line
    }, [data, sort, page, perPage]);

    const handleChangePage = (e, page) => {
        setPage(page);
    };
    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };
    const handleOnSort = (newOrder, index) => {
        setSort({ order: newOrder, orderBy: TableHeaders[index] });
    };

    return (
        <CustomTable
            page={page}
            headers={TableHeaders}
            onSort={handleOnSort}
            initialOrderIndex={0}
            rowsPerPage={perPage}
            count={data.length}
            onChangePage={handleChangePage}
            onChangeRows={handleChangeRows}
            classes={{ root: classes.table }}
            tooltips={{
                APR: 'APR is an estimate based on interest earned.',
                Principal: 'The amount of Axion (AXN) that is staked.',
                'Total BPD': 'The estimated total payout for all future BigPayDays the stake qualifies for.',
                Interest: 'The total interest this stake has earned. Includes BPD payouts.',
                Shares: 'Shares are used to determine the amount of wBTC rewards and BPD rewards.',
            }}
            rows={stakes.map((item) => {
                if (!item) return { columns: SkeletonTableRow({ count: 3 }) };
                return {
                    columns: [
                        <HorizontalCell>
                            <div className={classes.name}>
                                {item.name}&nbsp;
                                <Tooltip enterDelay={0} arrow title="Edit this stake">
                                    <div>
                                        <BiPencil style={{ cursor: 'pointer' }} onClick={() => props.onEdit(item)} />
                                    </div>
                                </Tooltip>
                            </div>
                        </HorizontalCell>,
                        moment(item.start).format('MMM DD, YYYY'),
                        <Tooltip arrow title={moment(item.end).format('MMM DD, YYYY hh:mm:ss a')}>
                            <span>{moment(item.end).format('MMM DD, YYYY')}</span>
                        </Tooltip>,
                        `${item.percent}%`,
                        <AxnValueTooltip value={item.principal} />,
                        item.shares.numberWithCommas(0),
                        <AxnValueTooltip value={item.interest} />,
                        <AxnValueTooltip value={item.bigPayDay} />,
                        `${item.apy.toFixed(2)}%`,
                        <HorizontalCell>
                            <Button
                                fullWidth
                                size="small"
                                override="translucent"
                                classes={{ root: classes.actionBtn }}
                                onClick={() => props[item.isMatured ? 'onActions' : 'onWithdraw'](item)}
                            >
                                {item.isMatured ? 'Actions' : 'Withdraw'}
                            </Button>
                            <Button
                                fullWidth
                                size="small"
                                override="translucent"
                                onClick={() => props.onEdit(item)}
                                classes={{ root: classes.actionBtn }}
                            >
                                Manage
                            </Button>
                        </HorizontalCell>,
                    ],
                };
            })}
        />
    );
};

export default ActiveMaturedTable;
