import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
    // Must be in r,g,b
    const color = theme.mode === 'dark' ? '15, 170, 214' : '184, 77, 16';

    // ARC STYLES
    const arcStyles = {
        arcReactor: {
            transform: 'scale(0.9)',
            marginTop: '10%',
            marginRight: 'auto',
            width: '250px',
            height: '250px',
            borderRadius: '50%',
            marginBottom: theme.spacing(0.5),
            boxShadow: `0px 0px 50px 15px rgba(${color}, 0.3), inset 0px 0px 50px 15px rgba(${color}, 0.3)`,
        },
        arcContainer: {
            width: '210px',
            height: '210px',
            borderRadius: '50%',
            position: 'absolute',
            marginLeft: '20px',
            marginTop: '20px',
        },
        semiArc: {
            left: '3%',
            top: '3%',
            width: '94%',
            height: '94%',
            position: 'absolute',
            borderRadius: '50%',
            textAlign: 'center',
            boxSizing: 'border-box',
            animation: '$rotate 4s linear infinite',
        },
        e7: {
            width: '95.25%',
            height: '95.25%',
            left: '2.5475%',
            right: '2.5475%',
            border: '6px solid transparent',
            background: 'transparent',
            MozBorderRadius: '50%',
            WebkitBorderRadius: '50%',
            borderRadius: '50%',
            transform: 'rotateZ(0deg)',
            transition: 'box-shadow 3s ease',
            textAlign: 'center',
            lineHeight: '100px',
        },
        e5_1: {
            color: `rgba(${color}, 0.15)`,
            border: '2px solid',
            borderLeft: '2px solid transparent',
            animation: '$rotate 5s linear infinite',
        },
        e5_2: {
            color: `rgba(${color}, 0.7)`,
            border: '4px solid',
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            animation: '$rotate_anti 4s linear infinite',
        },
        e5_3: {
            border: '2px solid',
            color: `rgba(${color}, 0.5)`,
        },
        e5_4: {
            color: `rgba(${color}, 0.15)`,
            border: '4px solid',
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            borderBottom: '4px solid transparent',
            animation: '$rotate_anti 2.5s linear infinite',
        },
        progressTextContainer: {
            marginTop: '35%',
        },
        centerProgressText: {
            fontSize: '1.25rem',
        },
        marks: {
            transform: 'scaleX(-1)',
            '& li': {
                width: 3,
                height: 11,
                display: 'block',
                position: 'absolute',
                marginLeft: '105px',
                marginTop: '-110px',
                animation: '$color_ease 3s infinite ease-in-out',
            },
        },

        '@keyframes color_ease': {
            '0%': { background: `rgba(${color}, 1)` },
            '50%': { background: `rgba(${color}, 0.3)` },
            '100%': { background: `rgba(${color}, 1)` },
        },
        '@keyframes rotate_anti': {
            '0%': { transform: 'rotateZ(360deg)' },
            '100%': { transform: 'rotateZ(0deg)' },
        },
        '@keyframes rotate': {
            '0%': { transform: 'rotateZ(0deg)' },
            '100%': { transform: 'rotateZ(360deg)' },
        },
    };

    // Generate tick rotation
    let rotate = 180;
    for (let i = 0; i < 60; ++i) {
        arcStyles.marks = {
            ...arcStyles.marks,
            [`& li:nth-child(${i + 1})`]: { transform: `rotate(${rotate}deg) translateY(125px)` },
        };
        rotate += 6;
        if (rotate > 360) rotate = 6;
    }

    return { ...arcStyles };
});

export default useStyles;
