import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme._shadows.card,
        backgroundColor: theme.brand.CardBG,
        borderRadius: 4,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    titleIcon: {
        marginRight: theme.spacing(1),
    },
    disabled: {
        opacity: 0.1,
    },
    buttons: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
    },
}));

export default useStyles;
