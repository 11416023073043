import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    page: {
        // maxWidth: 1200,
    },

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gridAutoRows: '1fr',
        gridColumnGap: '12px',
        gridRowGap: '5px',

        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gridAutoRows: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',

            '& > div': {
                marginTop: theme.spacing(3),
            },
        },
    },

    titleBox: {
        padding: 18,
    },
    contentBox: {
        padding: 18,
    },
    imageContainer: {
        position: 'relative',
    },

    owned: {
        position: 'absolute',
        height: 30,
        width: 30,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        top: -5,
        left: -5,
    },
    dno: {
        backgroundColor: theme.palette.error.main,
    },
    dh: {
        backgroundColor: theme.palette.success.main,
    },
    stackerNft: {
        width: '100%',
    },
    otherNft: {
        width: '100%',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },

    nftInfoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '9px 4px 9px 4px',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255, 255, 255, .25)',
        boxShadow: '4px 4px 12px rgb(0 0 0 / 20%)',
    },

    between: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    divider: {
        backgroundColor: 'rgba(255,255,255,0.2)',
        height: 1,
        width: '100%',
    },
}));

export default useStyles;
