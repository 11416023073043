import React from 'react';
import useStyles from './styles';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';

const SharedSelect = ({ open, token, tokens, setOpen, handleChangeToken }) => {
    const classes = useStyles();

    return (
        <div className={classes.select}>
            <TextField
                select
                fullWidth
                margin="dense"
                label="Currency"
                variant="outlined"
                SelectProps={{
                    open: open,
                    value: token.address,
                    onChange: handleChangeToken,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                    MenuProps: {
                        disableScrollLock: true,
                        classes: { paper: classes.selectMenu },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                    },
                }}
            >
                {tokens.map((t, idx) => (
                    <MenuItem value={t.address} key={idx}>
                        <Grid container justify="space-between" alignItems="center" alignContent="center">
                            <Grid item xs={2}>
                                <img alt="token" src={t.logoDark ?? t.logo} className={classes.tokenIcon} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>
                                    {t.symbol}&nbsp;-&nbsp;{t.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default SharedSelect;
