import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    slider: {
        width: '90% !important',
        margin: `16px 0`,
    },
}));

export default useStyles;
