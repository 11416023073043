import React from 'react';
import News from './News/News';
import useStyles from './styles';
import Page from 'components/Page/Page';
import { Grid } from '@mui/material';
import PageTitle from './PageTitle/PageTitle';
import Meta from '../../components/util/Meta';
import PromoBanner from 'components/PromoBanner/PromoBanner';
import QuickStats from './QuickStats/QuickStats';
import InfoButtons from './InfoButtons/InfoButtons';
import YoutubeVideo from './YoutubeVideo/YoutubeVideo';
import Background from 'components/Background';

const Dashboard = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Meta title="Axion | Dashboard" />

            <Background />
            <Page header={<PageTitle />}>
                <Grid container spacing={2}>
                    <Grid item lg={8} xs={12}>
                        <InfoButtons />
                    </Grid>
                    <Grid item lg={4} xs={12} className={classes.videoContainer}>
                        <YoutubeVideo videoID="ZV7GGFWCHq4" />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <PromoBanner />
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <QuickStats />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <News />
                    </Grid>
                </Grid>
            </Page>
        </React.Fragment>
    );
};

export default Dashboard;
