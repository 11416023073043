import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    colliderContainer: {
        display: 'flex',
        flexFlow: 'column',
    },
}));

export default useStyles;
