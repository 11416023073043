import { ethers } from 'ethers';
import ABI from './abi.json';

import NFT from './contracts/nft';
import Axion from './contracts/axion';
import Staker from './contracts/staker';
import Pledge from './contracts/pledge';
import Staking from './contracts/staking';
import Vesting from './contracts/vesting';
import Utility from './contracts/utility';
import MineManager from './contracts/mining';
import Accelerator from './contracts/accelerator';
import { BSC_RPC, ETH_RPC, POLYGON_RPC } from 'utils/variables';

const CONTRACT_INFO = ABI['mainnet'];

class BlockChain {
    static initialize({ address, chain, provider, signer }) {
        let _address = address;
        if (process.env.NODE_ENV === 'development') {
            // _address = '0x6c2BDA241B6ee1fd0116b52CE39a78249DD52Ce2';
        }

        BlockChain.provider = provider;
        BlockChain.address = _address;
        BlockChain.account = _address;
        BlockChain.signer = signer;
        BlockChain.contracts = BlockChain.loadContracts(chain);

        BlockChain.loadLibrary();
    }

    static getInfo() {
        return {
            contracts: BlockChain.contracts,
            address: BlockChain.address,
            provider: BlockChain.provider,
            signer: BlockChain.signer,
        };
    }

    static loadLibrary() {
        const info = BlockChain.getInfo();

        BlockChain.NFT = new NFT(info);
        BlockChain.Axion = new Axion(info);
        BlockChain.Staker = new Staker(info);
        BlockChain.Pledge = new Pledge(info);
        BlockChain.Staking = new Staking(info);
        BlockChain.Vesting = new Vesting(info);
        BlockChain.Utility = new Utility(info);
        BlockChain.Accelerator = new Accelerator(info);
        BlockChain.MineManager = new MineManager(info);

        BlockChain.library = {
            NFT: BlockChain.NFT,
            Axion: BlockChain.Axion,
            Staker: BlockChain.Staker,
            Pledge: BlockChain.Pledge,
            Staking: BlockChain.Staking,
            Vesting: BlockChain.Vesting,
            Utility: BlockChain.Utility,
            Accelerator: BlockChain.Accelerator,
            MineManager: BlockChain.MineManager,
        };
    }

    static loadContracts() {
        const contracts = { eth: {}, bsc: {} };

        const polyProvider = new ethers.providers.JsonRpcProvider(POLYGON_RPC);
        for (let contract of Object.keys(CONTRACT_INFO)) {
            const params = CONTRACT_INFO[contract];
            if (params.ADDRESS && params.ABI) {
                contracts[contract] = new ethers.Contract(params.ADDRESS, params.ABI, polyProvider);
            }
        }

        const ethProvider = new ethers.providers.JsonRpcProvider(ETH_RPC);
        for (let contract of Object.keys(CONTRACT_INFO.ETH_READ)) {
            const params = CONTRACT_INFO.ETH_READ[contract];
            if (params.ADDRESS && params.ABI) {
                contracts.eth[contract] = new ethers.Contract(params.ADDRESS, params.ABI, ethProvider);
            }
        }

        const bscProvider = new ethers.providers.JsonRpcProvider(BSC_RPC);
        for (let contract of Object.keys(CONTRACT_INFO.BSC_READ)) {
            const params = CONTRACT_INFO.BSC_READ[contract];
            if (params.ADDRESS && params.ABI) {
                contracts.bsc[contract] = new ethers.Contract(params.ADDRESS, params.ABI, bscProvider);
            }
        }

        return contracts;
    }

    static async getAllContractDetails() {
        if (!BlockChain.contracts) throw new Error('Contracts must be initialized');

        const [axion, pledgeEngine, staking, utility, accelerator] = await Promise.all([
            BlockChain.Axion.getInfo(),
            BlockChain.Pledge.getPledges(),
            BlockChain.Staking.getInfo(),
            BlockChain.Utility.getInfo(),
            BlockChain.Accelerator.getInfo(),
        ]);

        BlockChain.axion = axion;
        BlockChain.staking = staking;
        BlockChain.utility = utility;
        BlockChain.accelerator = accelerator;
        BlockChain.pledgeEngine = pledgeEngine;

        return { staking, accelerator, axion, utility, pledgeEngine };
    }
}

export default BlockChain;
