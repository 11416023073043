import React from 'react';
// Components
import { Typography } from '@mui/material';
// styles
import useStyles from './styles';

const PageTitle = ({}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Stacker NFT
                </Typography>
                <Typography className={classes.body} variant="h6" sx={{ maxWidth: '700px' }}>
                    The Stacker NFT provides the owner with a 1% bonus in the Accelerator and makes it possible to combine or 'STACK' your
                    bonus NFTs when you purchase and stake Axion using the Accelerator. This process provides the best results, both for the
                    individuals staking their Axion and the protocol as a whole. The maximum bonus you can receive in the Accelerator with
                    the Stacker is 10%.
                </Typography>
            </div>
        </div>
    );
};

export default PageTitle;
