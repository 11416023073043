import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { MAX_APR } from 'utils/variables';
import { Typography, useTheme } from '@mui/material';

const PageTitle = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <Typography
                className={clsx(classes.subtitle, {
                    [classes.supernovaGlow]: theme.mode === 'supernova',
                })}
            >
                Passive <b>Bitcoin income</b>.
                <br />
                Up to <b>{MAX_APR}% APR</b>. <b>Exclusive</b> launches.
                <br />
                Welcome to Axion, the <b>future of crypto</b>.<br className="break-on-large" />
            </Typography>
        </div>
    );
};

export default PageTitle;
