import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { useDrop } from 'react-dnd';
import { Typography, Zoom } from '@mui/material';

const DepositParticles = ({ nft, setNFT, colliding, collided }) => {
    const classes = useStyles();
    const [{ isOver }, dropRef] = useDrop({
        accept: 'nft',
        drop: (item) => {
            setNFT(item);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const deselectNFT = () => {
        if (!colliding && !collided) setNFT(nft);
    };

    return (
        <React.Fragment>
            <Typography className={classes.deposit}>Deposit Particles</Typography>
            <div
                ref={dropRef}
                onClick={deselectNFT}
                className={clsx(classes.colliderInput, {
                    [classes.colliding]: colliding || collided,
                    [classes.active]: isOver,
                    [classes.pointer]: nft && !colliding && !collided,
                })}
            >
                <Zoom in={nft && !collided} style={{ display: nft && !collided ? 'block' : 'none' }}>
                    <img
                        alt="chosen particle NFT"
                        src={nft?.metadata?.image}
                        className={clsx(classes.nft, {
                            [classes.active]: colliding,
                        })}
                    />
                </Zoom>

                <Zoom in={!nft || collided}>
                    <Typography className={classes.getStarted} style={{ display: !nft || collided ? 'block' : 'none' }}>
                        {collided ? 'Axion Particle NFT has been expended' : 'Select a Particle NFT below to get started'}
                    </Typography>
                </Zoom>
            </div>
        </React.Fragment>
    );
};

export default DepositParticles;
