import React, { useState } from 'react';

import Button from 'components/Button';
import SlideUp from 'components/Transitions/SlideUp';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import clsx from 'classnames';
import useStyles from './styles';
import { InfoOutlined, Warning, CheckCircle } from '@mui/icons-material';

const icons = {
    info: InfoOutlined,
    warning: Warning,
    success: CheckCircle,
};

const BasicModal = ({
    open,
    message,
    onClose,
    onConfirm,
    body,
    type = 'info',
    onSecondaryAction,
    secondaryActionText,
    title = 'Attention!',
    cancelButtonText = 'Cancel',
    confirmText = 'Continue',
}) => {
    const classes = useStyles();
    const [confirming, setConfirming] = useState(false);

    function handleConfirm() {
        setConfirming(true);
        try {
            onConfirm?.();
            onClose();
        } catch (error) {}
        setConfirming(false);
    }

    const Icon = icons[type];

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }} TransitionComponent={SlideUp} /*disableScrollLock*/>
            <div className={classes.dialogTitle}>
                <Icon className={clsx(classes.icon, classes[type])} />
                <Typography variant="h3">{title}</Typography>
            </div>

            <DialogContent className={classes.contentContainer}>
                {body && body}
                {message && (
                    <Typography variant="body1" className={classes.message}>
                        {message}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} color="primary" disabled={confirming} size="large">
                    {cancelButtonText}
                </Button>
                {onSecondaryAction && (
                    <Button onClick={onSecondaryAction} color="primary" size="large">
                        {secondaryActionText}
                    </Button>
                )}
                {onConfirm && (
                    <Button onClick={handleConfirm} color="primary" size="large" loading={confirming}>
                        {confirmText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default BasicModal;
