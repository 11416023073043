import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';
import clsx from 'classnames';
import { useCountUp } from 'react-countup';
import StakeInput from 'components/StakeInput';
import getStakeInfo from 'utils/get-stake-info';
import { ContractContext } from 'web3/WagmiListener';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ColliderSettings = ({ nft, colliding, days, setDays, handleCollide, preTX }) => {
    const classes = useStyles();
    const [minStakeDays, setMinStakeDays] = useState(120);
    const { contractInfo, loadingContractInfo } = useContext(ContractContext);
    const { countUp, update } = useCountUp({ start: 0, end: 0, duration: 1, separator: ',' });

    useEffect(() => {
        if (nft) {
            update(nft.metadata.particles);
        } else update(0);

        // eslint-disable-next-line
    }, [nft]);

    useEffect(() => {
        if (!loadingContractInfo) {
            if (contractInfo.accelerator) {
                const accelerator = contractInfo.accelerator;
                if (accelerator) setMinStakeDays(accelerator.minStakeDays);
            }
        }
    }, [contractInfo, loadingContractInfo]);

    const { totalShares } = useMemo(() => {
        let shareRate = 1;
        if (!loadingContractInfo) shareRate = contractInfo.staking.shareRate / 1e18;

        return getStakeInfo(nft?.metadata?.particles || 0, days.value || minStakeDays, shareRate);

        // eslint-disable-next-line
    }, [contractInfo, nft, days, loadingContractInfo]);

    return (
        <React.Fragment>
            <Grid container spacing={2} justify="space-between">
                <Grid item lg={6} xs={12}>
                    <Typography>Number of Particles</Typography>
                    <div className={classes.numberOfParticles}>
                        <Typography className={classes.numberOfParticlesValue}>{countUp}</Typography>
                    </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography>Chance of Collision</Typography>
                    <div className={classes.numberOfParticles}>
                        <Typography className={classes.chance}>{nft ? '1 IN 25' : '0'}</Typography>
                    </div>
                </Grid>
            </Grid>

            <div className={classes.stakeDaysContainer}>
                <Typography className={classes.stakeLengthText}>Choose how long to stake your AXN if a collision occurs:</Typography>
                <StakeInput
                    hideStats
                    disabled={preTX}
                    stakeDays={days}
                    onChangeDays={setDays}
                    stakeLabel="Stake Length"
                    minStakeDays={minStakeDays}
                    stakeDaysHelperText={`Min: ${minStakeDays} days, Max: 5,555 days`}
                />
                <div className={classes.totals}>
                    <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }}>
                        <Grid item>
                            <Typography className={clsx(classes.value, classes.green)}>
                                {(nft?.metadata?.particles || 0).numberWithCommas(0)}
                            </Typography>
                            <Typography className={classes.subValue}>Principal (AXN)</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.value, classes.green)}>{totalShares?.numberWithCommas(0)}</Typography>
                            <Typography className={classes.subValue}>Total Shares</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.value, classes.green)}>
                                $
                                {((nft?.metadata?.particles || 0) * (contractInfo?.axion?.usdPerAXN || 0)).toLocaleString('en-US', {
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                            <Typography className={classes.subValue}>Today's Value</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Typography className={classes.explain}>
                Your particle NFT will be burned when you engage the collider. If the collision is successful, new AXN will be created and a
                new stake will appear on the staking page.
            </Typography>
            <LoadingButton
                fullWidth
                variant="contained"
                onClick={handleCollide}
                className={classes.startButton}
                loading={preTX}
                // disabled={+days.value < minStakeDays || !nft || colliding || preTX}
            >
                Engage the Collider
            </LoadingButton>
        </React.Fragment>
    );
};

export default ColliderSettings;
