import React, { useContext, useEffect, useMemo, useState } from 'react';
import MintNFT from './MintNFT';
import useStyles from './styles';
import SplitStake from './SplitStake';
import { BiPencil } from 'react-icons/bi';
import Button from 'components/Button/Button';
import { ContractContext } from 'web3/WagmiListener';
import SwipeableViews from 'react-swipeable-views';
import RenameStake from './RenameStake/RenameStake';
import SlideUp from 'components/Transitions/SlideUp';
import { setSnackbar } from 'redux/actions/snackbar';
import UpgradeStake from 'components/UpgradeStake';
import { RENAME_MAX_LENGTH } from 'utils/variables';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Tab,
    Tabs,
    Typography,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
    useTheme,
} from '@mui/material';

const EditModal = ({ open, data, onClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { accelerator } = useSelector((store) => store);

    const [tab, setTab] = useState(0);
    const [name, setName] = useState('');
    const [percent, setPercent] = useState(30);
    const [loading, setLoading] = useState(false);
    const [mintChecked, setMintChecked] = useState(false);
    const [splitChecked, setSplitChecked] = useState(false);
    const [renameChecked, setRenameChecked] = useState(false);

    const { library, contractInfo, getWalletDetails, loadingContractInfo } = useContext(ContractContext);

    const maxSharesInfo = useMemo(() => {
        if (!loadingContractInfo) return { ...contractInfo.staking.maxShare };
        return { active: false, maxDays: 1887 };
    }, [loadingContractInfo, contractInfo]);

    const utilityCostInfo = useMemo(() => {
        if (!loadingContractInfo) return { ...contractInfo.utility };
        return { nameCost: 0, splitCost: 0 };
    }, [loadingContractInfo, contractInfo]);

    useEffect(() => {
        if (utilityCostInfo.splitCost === 0) setSplitChecked(true);
        if (utilityCostInfo.nameCost === 0) setRenameChecked(true);
    }, [open, utilityCostInfo]);

    const tabData = useMemo(() => {
        const _handleAction = async (action, prop) => {
            setLoading(true);

            let lib = 'Utility';
            if (action === 'upgrade' || action === 'convertToNFT') lib = 'Staking';

            try {
                if (lib === 'Utility') await library.Utility[action](data.stake.id, prop, utilityCostInfo);
                else await library.Staking[action](data.stake.id, prop);

                handleClose();
                getWalletDetails();
            } catch (error) {
                dispatch(setSnackbar({ message: error.message }));
            } finally {
                setLoading(false);
            }
        };

        switch (tab) {
            case 0:
                return {
                    action: 'Rename',
                    disabled: name.length === 0 || !renameChecked,
                    handle: () => _handleAction('rename', name),
                    checkbox: { checked: renameChecked, setChecked: setRenameChecked, cost: utilityCostInfo.nameCost },
                };
            case 1:
                return {
                    action: 'Split',
                    disabled: !splitChecked,
                    handle: () => _handleAction('split', percent),
                    checkbox: { checked: splitChecked, setChecked: setSplitChecked, cost: utilityCostInfo.splitCost },
                };
            case 2:
                return {
                    action: 'Upgrade',
                    disabled: !maxSharesInfo.active || data?.stake?.stakeDays >= maxSharesInfo.maxDays,
                    handle: () => _handleAction('upgrade'),
                    checkbox: null,
                };
            case 3:
                let price = 6;
                const scale = data?.stake?.principal / 2000000;
                if (scale > 1) price += scale;
                const cost = Math.min(Math.floor(price), 200);

                return {
                    action: 'Mint NFT',
                    disabled: !mintChecked,
                    handle: () => _handleAction('convertToNFT', cost),
                    checkbox: { checked: mintChecked, setChecked: setMintChecked, cost },
                };
            default:
                return {
                    action: 'Rename',
                    disabled: name.length === 0 || !renameChecked,
                    handle: () => _handleAction('rename', name),
                    checkbox: { checked: renameChecked, setChecked: setRenameChecked, cost: utilityCostInfo.nameCost },
                };
        }

        // eslint-disable-next-line
    }, [tab, name, percent, maxSharesInfo, utilityCostInfo, data, renameChecked, splitChecked, mintChecked]);

    const handleNameChange = (ev) => {
        const value = ev.target.value;
        if (value.length <= RENAME_MAX_LENGTH) setName(value);
    };

    const handleTabChange = (_, value) => {
        setTab(value);

        setName('');
        setPercent(50);
    };

    const handleClose = () => {
        setTab(0);
        setName('');
        setPercent(50);
        setLoading(false);
        setSplitChecked(false);
        setRenameChecked(false);

        onClose();
    };

    return (
        <Dialog
            scroll={'body'}
            maxWidth={'md'}
            open={open === true}
            onClose={handleClose}
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                <Typography variant="h3" className={classes.title}>
                    <BiPencil className={classes.titleIcon} />
                    Manage Your Stake
                </Typography>
            </DialogTitle>

            <Tabs value={tab} variant="fullWidth" textColor="primary" indicatorColor="primary" onChange={handleTabChange}>
                <Tab label={<Typography>Rename Stake</Typography>} />
                <Tab
                    disabled={data?.stake?.isMatured}
                    classes={{ disabled: classes.disabled }}
                    label={<Typography>Split Stake</Typography>}
                />
                <Tab
                    classes={{ disabled: classes.disabled }}
                    label={<Typography>Upgrade Stake</Typography>}
                    disabled={!maxSharesInfo.active || data?.stake?.stakeDays >= maxSharesInfo.maxDays}
                />
                <Tab
                    disabled={data?.stake?.isNFT}
                    classes={{ disabled: classes.disabled }}
                    label={<Typography>Mint Stake NFT</Typography>}
                />
            </Tabs>

            <br />

            <DialogContent>
                <SwipeableViews
                    disabled
                    index={tab}
                    ignoreNativeScroll
                    animateHeight={true}
                    containerStyle={{ transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s' }}
                >
                    {/* keep DIVs, fixes bug */}
                    <div>
                        <RenameStake data={data} name={name} setName={handleNameChange} />
                    </div>
                    <div>
                        <SplitStake data={data} percent={percent} setPercent={setPercent} />
                    </div>
                    <div>
                        <UpgradeStake data={data} />
                    </div>
                    <div>
                        <MintNFT />
                    </div>
                </SwipeableViews>
            </DialogContent>

            {tabData.checkbox?.cost > 0 && (
                <div className={classes.checkbox}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    checked={tabData.checkbox.checked}
                                    onChange={(ev) => tabData.checkbox.setChecked(ev.target.checked)}
                                />
                            }
                            label={
                                <Typography className={classes.serviceFee}>
                                    I understand that a&nbsp;
                                    <span className={classes.serviceFeeBold}>
                                        {tabData.checkbox.cost} MATIC (${(tabData.checkbox.cost * accelerator.tokenPrices.MATIC).toFixed(2)}{' '}
                                        USD)
                                    </span>{' '}
                                    service fee, applies to this transaction.
                                </Typography>
                            }
                        />
                    </FormGroup>
                </div>
            )}

            <DialogActions>
                <div className={classes.buttons}>
                    <Button fullWidth color="primary" variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        fullWidth
                        color="primary"
                        loading={loading}
                        variant="contained"
                        onClick={tabData.handle}
                        disabled={tabData.disabled || loading}
                        override={theme.mode === 'dark' ? 'blue' : 'supernova'}
                    >
                        {tabData.action}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default EditModal;
