import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: theme.mixins.bar.height,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme._shadows.bar,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            flexDirection: 'column',
            borderRadius: 0,
        },
        marginBottom: theme.spacing(3),
    },
    barTitle: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center !important',
        },
    },
    barPaper: {
        borderRadius: '0.5rem',
        height: 70,
        width: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.brand.CardBG,
        marginRight: theme.spacing(2),
    },
    barIcon: {
        margin: 'auto',
        fontSize: 34,
    },
    title: {
        fontSize: 42,
        fontWeight: '500',
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
}));

export default useStyles;
