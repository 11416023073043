import { makeStyles } from '@mui/styles';
import bgImg from 'assets/images/accelerator-bg-1920.jpg';
import bgsupernova from 'assets/images/AxionSupernovaBG1.jpg';

const useStyles = makeStyles((theme) => ({
    particles: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        userSelect: 'none',
        zIndex: -1,

        '&:before': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundImage: `url(${theme.mode === 'dark' ? bgImg : bgsupernova})`,
            opacity: 1,
        },
    },
    title: {
        fontWeight: 300,
        fontSize: '1.1rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    bold: {
        fontWeight: 500,
    },
    info: {
        cursor: 'pointer',
        fontSize: '0.8rem',
        verticalAlign: 'top',
    },
    confetti: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    spacing: {
        height: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(4),
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    titleText: {
        fontWeight: 300,
        fontSize: '1.1rem',
        color: theme.brand.SecondaryText,

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.05rem',
        },
    },
    subtitle: {
        fontSize: '0.85rem',
    },
    toggleAnimations: {
        opacity: 0.6,
        float: 'right',
        position: 'relative',
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toggleLabel: {
        fontSize: '0.8rem',
    },
    fontWeight: 300,
    fontSize: '1.1rem',

    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    },
    value: {
        fontWeight: 500,
        color: theme.brand.SecondaryText,
        fontSize: '1.1rem',
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    amount: {
        fontWeight: 300,
        textAlign: 'right',
        maxHeight: 20, // Fixes weird bug when amount = 0.
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
        },
    },
    divider: {
        borderBottom: `1px solid ${theme.brand.border}`,
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    term: {
        fontWeight: 300,
        fontSize: '0.7rem',
        marginTop: theme.spacing(0.75) * -1,
    },
    pointer: {
        cursor: 'pointer',
    },
}));

export default useStyles;
