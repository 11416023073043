import React, { useRef, useState } from 'react';
import useStyles from './styles';
import { Card, Grid, Tooltip, Typography } from '@mui/material';

const NFTCard = ({ metadata, balance, type, gridSize }) => {
    const classes = useStyles();
    const vidRef = useRef(null);
    const [hover, setHover] = useState(null);

    const onHover = (ev) => {
        if (type === 'particle') return;
        if (ev.type === 'mouseenter') {
            vidRef.current.play();
            setHover(ev.target.id);
        } else {
            setHover(null);
            vidRef.current.load();
            vidRef.current.pause();
        }
    };

    return (
        <Card onMouseEnter={onHover} onMouseLeave={onHover} className={classes.nftContainer} elevation={hover === metadata.name ? 24 : 6}>
            <video
                loop
                ref={vidRef}
                id={metadata.name}
                className={classes.art}
                poster={metadata.image}
                style={{ marginBottom: gridSize <= 2 ? '-5px' : 0 }}
            >
                <source src={metadata.animation_url} type="video/webm" />
            </video>
            {gridSize > 2 && (
                <div className={classes.textContainer} id={metadata.name}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={balance > 1 ? 10 : 12}>
                            <Typography variant="h2" style={{ fontSize: gridSize < 4 ? '1rem' : '1.2rem' }}>
                                {metadata.name}
                            </Typography>
                        </Grid>
                        {balance > 1 && (
                            <Grid item xs={2} className={classes.nftBalance}>
                                <Tooltip enterDelay={0} placement="top" arrow title={`${balance} copies owned`}>
                                    <Typography>
                                        <span style={{ fontSize: '0.8rem' }}>x</span>
                                        {balance}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}
        </Card>
    );
};

export default NFTCard;
