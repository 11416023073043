import Contract from './contract';
import { store } from 'redux/config';
import NFT from './nft';
import { setTheme } from 'redux/actions/theme';
import { STAKE_TYPES, CONTRACT_INFO, APR, BPD_TIMES, BPD_AMOUNTS } from 'utils/variables';
import Web3 from 'web3';
import BlockChain from 'web3/Blockchain';

const dispatch = store.dispatch;

class Staker extends Contract {
    constructor(params) {
        super();
        this.contracts = params?.contracts;
        this.signer = params?.signer;
        this.address = params?.address;
        this.account = params?.address;
        this.provider = params?.provider;

        this.initial = true;
        this.nftEngine = new NFT(params);
        this.stakeStatuses = {
            Unknown: '0',
            Withdrawn: '1',
            Active: '2',
        };
    }

    async get() {
        const [maticBalance, settings, balance, sessionsOfNFT, sessionsOf, DaoShares] = await Promise.all([
            this.provider.getBalance(this.account),
            this.contracts.StakeManager.getSettings(),
            this.contracts.AXN.balanceOf(this.account),
            this.contracts.StakeToken.getStakeIdsOf(this.account),
            this.contracts.StakeCustodian.getStakeIdsOf(this.account),
            this.contracts.DataReader.getDaoShares(this.account),
        ]);
        const nfts = await this.nftEngine.getNFTs(sessionsOfNFT);

        // Settings/Util
        this.secondsInDay = +settings.secondsInDay;
        this.startContract = +settings.contractStartTimestamp;
        this.isNFT = (id) => [...sessionsOfNFT].includes(id);
        this.hasNFT = (id) => {
            if (!nfts || nfts.collection.length === 0) return false;
            const found = nfts.collection.find((n) => {
                return `${n.metadata.id}` === `${CONTRACT_INFO.NFTCollection[id]}`;
            });
            return found ? found.balance > 0 : false;
        };

        const v3Stakes = [...sessionsOfNFT, ...sessionsOf];
        const v3StakeInfo = [];
        while (v3Stakes.length) {
            const ids = v3Stakes.splice(0, 200);
            const stakes = await this.contracts.StakeReader.listStakes(ids);
            v3StakeInfo.push(...stakes);
        }
        const stakes = this.formatStakes(v3StakeInfo);

        const sortedSessions = {};
        const stats = {
            totalBPD: 0,
            activeBPD: 0,
            totalShares: 0,
            totalStaked: 0,
            totalInterest: 0,
            activeInterest: 0,
        };

        for (let i = 0; i < stakes.length; ++i) {
            const stake = stakes[i];
            const info = stake.info;

            stake.bigPayDay = info.bpd / 1e18;
            stake.interest = info.interest / 1e18;

            // Add BPD earnings to interest
            const bpdShares = BlockChain?.axion?.bpdShares;
            if (stake.bigPayDay !== 0 && bpdShares) {
                for (let i = 0; i < 5; ++i) {
                    if (Date.now() / 1000 > BPD_TIMES[i] && stake.startSeconds < BPD_TIMES[i]) {
                        const amount = (stake.shares / BlockChain.axion.bpdShares[i]) * BPD_AMOUNTS[i];
                        stake.interest += amount; // Add to interest
                        stake.bigPayDay -= amount; // remove from expected earnings, since its in the interest now.
                        stake.bpdPayouts[i] = amount; // Track so we can show on the UI

                        if (stake.bigPayDay < 0) stake.bigPayDay = 0;
                    } else break; // break - any future iterations would also not pass.
                }
            }

            stake.apy = this.calcAPY(stake);
            stake.percent = Math.max(this.calcProgress(stake), 0);

            if (stake.isWithdrawn) {
                stake.penalty = info.penalty / 1e18;
                stake.payout = stake.principal + stake.interest;
            } else {
                stats.totalBPD += stake.bigPayDay;
                stats.totalShares += stake.shares;
                stats.activeBPD += stake.bigPayDay;
                stats.totalStaked += stake.principal;
                stats.totalInterest += stake.interest;
                stats.activeInterest += stake.interest;
            }

            // Add to proper category
            const category = this.getStakeCategory(stake);
            sortedSessions[category] ? sortedSessions[category].push(stake) : (sortedSessions[category] = [stake]);
        }

        // Set theme if they have supernova
        try {
            const { theme } = store.getState();
            if (this.hasNFT('SUPERNOVA') && this.initial && !localStorage.getItem('prefersDark')) dispatch(setTheme('supernova'));
            else if (this.initial && theme.mode !== 'dark') dispatch(setTheme('dark'));
            this.initial = false;
        } catch (err) {
            console.error("Error checking NFT's", err);
        }

        return {
            stakes,
            ...stats,
            sortedSessions,
            hasNFT: this.hasNFT,
            nfts: nfts.collection,
            DaoShares: DaoShares / 1e18,
            maticBalance: maticBalance / 1e18,
            colliderResults: nfts.colliderResults,
            balance: balance.formatUnits(),
        };
    }

    formatStakes(stakes) {
        return stakes.map((data) => {
            const { id, stake, interest, payout, penalty, bpdAmt } = data;
            const name = stake.name || `Stake #${id}`;

            const shares = stake.shares / 1e18;
            const principal = stake.amount / 1e18;
            const stakeDays = +stake.stakingDays;

            const firstPayout = +stake.firstInterestDay;
            const lastPayout = firstPayout + stakeDays;

            const startSeconds = +stake.start;
            const endSeconds = startSeconds + stakeDays * this.secondsInDay;

            const start = new Date(startSeconds * 1000);
            const end = new Date(endSeconds * 1000);

            const isNFT = this.isNFT(id);
            const isMatured = Date.now() > endSeconds * 1000;

            const isWithdrawn = `${stake.status}` === `${this.stakeStatuses.Withdrawn}`;

            let _interest = interest;
            const minInterestPerDay = (principal * APR) / 100 / 365;
            const totalMinInterest =
                minInterestPerDay *
                this.getDaysStaked({
                    isMatured,
                    end,
                    start,
                }); // Calculate the total interest to maintain the minimum APR
            if (_interest / 1e18 < totalMinInterest) _interest = Web3.utils.toWei(`${totalMinInterest}`);

            return {
                id,
                name,

                info: {
                    bpd: bpdAmt,
                    interest: _interest,
                    payout: payout,
                    penalty: penalty,
                },

                shares,
                principal,
                stakeDays,
                lastPayout,
                firstPayout,

                end,
                start,
                endSeconds,
                startSeconds,

                isNFT,
                isMatured,
                isWithdrawn,

                apy: 0,
                payout: 0,
                penalty: 0,
                interest: 0,
                bigPayDay: 0,
                bpdPayouts: [],

                _raw: stake,
            };
        });
    }

    getStakeCategory(stake) {
        if (stake.isWithdrawn) return STAKE_TYPES.COMPLETED_STAKES;
        else if (stake.isMatured) return STAKE_TYPES.MATURED_STAKES;
        else return STAKE_TYPES.ACTIVE_STAKES;
    }

    getDaysStaked(stake) {
        const end = stake.isMatured ? stake.end.getTime() : Date.now();
        return Math.floor((end - stake.start.getTime()) / (this.secondsInDay * 1000));
    }

    calcAPY(stake) {
        if (stake.principal === 0) return APR;
        const daysStaked = this.getDaysStaked(stake);
        if (daysStaked === 0) return APR;
        return Math.max(((stake.interest * 100) / stake.principal / daysStaked) * 365, APR);
    }

    calcProgress(stake) {
        const currentTime = new Date() / 1000;
        const endTime = parseInt(stake.endSeconds);
        const startTime = parseInt(stake.startSeconds);
        return Math.min(((currentTime - startTime) / (endTime - startTime)) * 100, 100).toFixed(2);
    }

    async calcPayoutAndPenalty(stake) {
        const stakeDays = stake.stakeDays;
        const startSeconds = stake.startSeconds;
        const principal = Web3.utils.toWei(`${Math.floor(stake.principal)}`);
        const interest = Web3.utils.toWei(`${Math.floor(stake.interest)}`);

        let payoutPenalty = 0;
        try {
            // Atleast wait day.
            if (startSeconds + 86400 > new Date().getTime() / 1000) {
                payoutPenalty = await this.contracts.StakeBurner.getPayoutAndPenalty(principal, startSeconds, stakeDays, interest);
            }
        } catch (err) {
            console.log('payoutPenalty Failed', stake);
        }

        return payoutPenalty;
    }

    async calcBPD(shares, start, end) {
        let bpd = 0;
        if (shares === '0') return bpd;

        try {
            bpd = await this.contracts.BPD.getBpdAmount(shares, start, end);
        } catch (err) {
            console.log('getBpdAmount failed', { shares, start, end });
        }

        return bpd;
    }

    async calcInterest(stake) {
        let interest = 0;

        try {
            interest = (await this.contracts.StakeManager.getStakeAndInterestById(stake.id)).interest;
            const minInterestPerDay = (stake.principal * APR) / 100 / 365; // Calculate the minimum interest per day to maintain the minimum APR
            const totalMinInterest = minInterestPerDay * this.getDaysStaked(stake); // Calculate the total interest to maintain the minimum APR

            // If the interest earned is lower then minimum APR, we give the minimum
            if (interest / 1e18 < totalMinInterest) interest = Web3.utils.toWei(`${totalMinInterest}`);
        } catch (err) {
            console.log('getStakeInterest Failed', stake, err);
        }

        return interest;
    }

    async getBalance(tokenAddress, decimals) {
        let balance = 0;
        try {
            balance = await this.getBalanceOf(tokenAddress);
        } catch (err) {
            console.log('getBalance failed', err.message);
        }

        return balance / 10 ** decimals;
    }

    async getAllowance(tokenAddress) {
        let allowance = 0;
        try {
            allowance = await this.getAllowanceOf(tokenAddress, CONTRACT_INFO.Accelerator.ADDRESS);
        } catch (err) {
            console.log('getAllowance failed', err.message);
        }

        return allowance / 1e18;
    }
}

export default Staker;
