import React, { useContext, useEffect, useState } from 'react';
import clsx from 'classnames';

import Page from 'components/Page';
import PageTitle from './PageTitle';
import { Box, Grid, Typography } from '@mui/material';
import Meta from '../../components/util/Meta';
import Background from 'components/Background/Background';
import { Check, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Card from 'components/Card/Card';

import DiamondStake from 'assets/images/nfts/diamond-stake.jpg';
import PhoenixGold from 'assets/images/nfts/phoenix-rising-gold.jpg';
import PhoenixSilver from 'assets/images/nfts/phoenix-rising-silver.jpg';
import PhoenixStandard from 'assets/images/nfts/phoenix-rising-standard.jpg';
import StackerNFT from 'assets/images/nfts/stacker-nft.jpeg';
import useStyles from './styles';
import { ContractContext } from 'web3/WagmiListener';
import { CONTRACT_INFO } from 'utils/variables';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { useAccount } from 'wagmi';

const nfts = [
    { name: 'Axion Diamond Stake', id: 'DIAMONDSTAKE', percent: '5%', image: DiamondStake },
    { name: 'Axion Gold Phoenix NFT', id: 'PHOENIX_GOLD', percent: '4%', image: PhoenixGold },
    { name: 'Axion Silver Phoenix NFT', id: 'PHOENIX_SILVER', percent: '3%', image: PhoenixSilver },
    { name: 'Axion Standard Phoenix NFT', id: 'PHOENIX_STANDARD', percent: '2%', image: PhoenixStandard },
];

const Stacker = () => {
    const classes = useStyles();
    const [stacker, setStacker] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const { user, library, getWalletDetails, loadingContractInfo } = useContext(ContractContext);
    const dispatch = useDispatch();
    const { isConnected } = useAccount();

    const cost = parseFloat(stacker?.metadata?.purchaseInfo?.mintPriceNative?.formatUnits(6) ?? '0').toFixed(2);

    useEffect(() => {
        if (user && isConnected) {
            getStackerNft();
        }

        if (!loadingContractInfo && !isConnected) {
            getStackerNftCheap();
        }
    }, [user, loadingContractInfo]);

    async function getStackerNftCheap() {
        const nfts = await library.NFT.getUtilities();
        const stacker = nfts.find((n) => {
            return `${n.id}` === CONTRACT_INFO.NFTCollection['STACKER_NFT'];
        });
        const totalSupply = await library.NFT.getTotalSupplyOf(CONTRACT_INFO.NFTCollection['STACKER_NFT']);

        setStacker({
            metadata: {
                purchaseInfo: {
                    mintable: stacker.mintable,
                    maxSupply: stacker.maxSupply,
                    mintPrice: stacker.mintPrice,
                    maxPurchaseTx: stacker.maxPurchaseTx,
                    mintPriceNative: stacker.mintPriceNative,
                    purchaseWithAxion: stacker.purchaseWithAxion,
                },
            },
            totalSupply: totalSupply.toNumber(),
            balanceOfUSDC: 0,
            allowance: 0,
        });
    }

    async function getStackerNft() {
        const stacker = user.nfts.find((n) => {
            return `${n.metadata.id}` === CONTRACT_INFO.NFTCollection['STACKER_NFT'];
        });
        const totalSupply = await library.NFT.getTotalSupplyOf(CONTRACT_INFO.NFTCollection['STACKER_NFT']);
        const allowance = await library.NFT.getAllowanceOf(CONTRACT_INFO.Tokens.USDC, CONTRACT_INFO.NFTCollection.ADDRESS);
        const balanceOfUSDC = await library.NFT.getBalanceOf(CONTRACT_INFO.Tokens.USDC);

        setStacker({
            ...stacker,
            balanceOfUSDC: parseFloat(balanceOfUSDC.formatUnits(6)),
            allowance: allowance,
            totalSupply: totalSupply.toNumber(),
        });
    }

    function getBonusWithStacker() {
        let total = 1;
        if (user?.hasNFT?.('DIAMONDSTAKE')) total += 5;
        if (user?.hasNFT?.('PHOENIX_GOLD')) total += 4;
        if (user?.hasNFT?.('PHOENIX_SILVER')) total += 3;
        if (user?.hasNFT?.('PHOENIX_STANDARD')) total += 2;

        return Math.min(10, total);
    }

    function getBonusWithoutStacker() {
        if (user?.hasNFT?.('DIAMONDSTAKE')) return 5;
        if (user?.hasNFT?.('PHOENIX_GOLD')) return 4;
        if (user?.hasNFT?.('PHOENIX_SILVER')) return 3;
        if (user?.hasNFT?.('PHOENIX_STANDARD')) return 2;
        return 0;
    }

    async function purchase() {
        setSubmitting(true);
        try {
            if (stacker.allowance.lt(stacker.metadata.purchaseInfo.mintPriceNative)) {
                await library.NFT.approveUSDC();
                setStacker({ ...stacker, allowance: 9999999999 });
            }

            await library.NFT.purchaseNFT(CONTRACT_INFO.NFTCollection['STACKER_NFT'], 1);
            await getWalletDetails();
            dispatch(setSnackbar({ message: 'Stacker NFT was purchased successfully!', type: 'success' }));
        } catch (error) {
            dispatch(setSnackbar({ message: error.message }));
        }
        setSubmitting(false);
    }

    const hasStacker = user?.hasNFT?.('STACKER_NFT') ?? false;

    const insufficientUSDC = stacker?.balanceOfUSDC < parseFloat(cost);
    const maxSupplyReached = stacker?.totalSupply === stacker?.metadata?.purchaseInfo?.maxSupply?.toNumber();
    const lessThenAllowance = stacker?.allowance?.lt?.(stacker?.metadata?.purchaseInfo?.mintPriceNative ?? '0') ?? true;

    return (
        <React.Fragment>
            <Meta title="Axion | Stacker" />
            <Background />
            <Page classes={{ root: classes.page }} header={<PageTitle type="official" />}>
                <Box className={classes.grid}>
                    <Box item xs={12} md={4}>
                        <Card className={classes.titleBox} sx={{ marginBottom: '16px' }}>
                            <Typography variant="h1">Purchase the Stacker NFT</Typography>
                            <Typography variant="h6">
                                {hasStacker ? 'Congratulations, You Own A Stacker NFT!' : 'You Do Not Currently Own A Stacker NFT'}
                            </Typography>
                        </Card>
                        <Card className={classes.contentBox}>
                            <Box className={classes.imageContainer} sx={{ marginBottom: '16px' }}>
                                <Box
                                    className={clsx(classes.owned, {
                                        [classes.dno]: !hasStacker,
                                        [classes.dh]: hasStacker,
                                    })}
                                >
                                    {!hasStacker && <Close />}
                                    {hasStacker && <Check />}
                                </Box>
                                <img src={StackerNFT} className={classes.stackerNft} />
                            </Box>
                            <LoadingButton
                                disabled={
                                    hasStacker ||
                                    maxSupplyReached ||
                                    insufficientUSDC ||
                                    loadingContractInfo ||
                                    !stacker?.metadata?.purchaseInfo?.mintable
                                }
                                onClick={purchase}
                                loading={submitting}
                                variant="contained"
                                fullWidth
                                sx={{ marginBottom: '4px' }}
                            >
                                {stacker?.balanceOfUSDC < parseFloat(cost)
                                    ? 'Insufficient USDC balance'
                                    : lessThenAllowance
                                    ? 'Approve USDC'
                                    : 'Purchase The Stacker'}
                            </LoadingButton>
                            <Typography variant="h6">
                                Current Cost: ${cost} USDC | Supply: {stacker?.totalSupply ?? '?'} /
                                {stacker?.metadata?.purchaseInfo?.maxSupply?.toNumber() ?? '?'}
                            </Typography>
                        </Card>
                    </Box>
                    <Box item xs={12} md={4}>
                        <Card className={classes.titleBox} sx={{ marginBottom: '16px' }}>
                            <Typography variant="h1">Bonus NFTs You Currently Own</Typography>
                            <Typography variant="h6">The Highest Bonus NFT is Applied without the Stacker</Typography>
                        </Card>
                        <Card className={classes.contentBox}>
                            <Grid container spacing={2}>
                                {nfts.map((nft) => {
                                    const hasNft = user?.hasNFT?.(nft.id) ?? false;

                                    return (
                                        <Grid item xs={12} md={6} key={nft.name}>
                                            <Box className={classes.imageContainer}>
                                                <Box
                                                    className={clsx(classes.owned, {
                                                        [classes.dno]: !hasNft,
                                                        [classes.dh]: hasNft,
                                                    })}
                                                >
                                                    {!hasNft && <Close />}
                                                    {hasNft && <Check />}
                                                </Box>
                                                <img src={nft.image} className={classes.otherNft} />
                                                <Box className={classes.nftInfoBox}>
                                                    <Typography sx={{ fontSize: 12 }}>{nft.name}</Typography>
                                                    <Typography sx={{ fontSize: 12 }}>{nft.percent}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Card>
                    </Box>
                    <Box item xs={12} md={4}>
                        <Card className={classes.titleBox} sx={{ marginBottom: '16px' }}>
                            <Typography variant="h1">Total Bonus You Will Receive</Typography>
                            <Typography variant="h6">You Must Own a Stacker NFT For Your Bonus To Stack</Typography>
                        </Card>
                        <Card className={classes.contentBox}>
                            <Typography variant="h3" sx={{ marginBottom: '24px' }}>
                                Current Bonus You Will Receive in the Accelerator
                            </Typography>
                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Diamond NFT</Typography>
                                <Typography variant="h2">{user?.hasNFT?.('DIAMONDSTAKE') ? '5%' : '0%'}</Typography>
                            </Box>
                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Gold Phoenix</Typography>
                                <Typography variant="h2">{user?.hasNFT?.('PHOENIX_GOLD') ? '4%' : '0%'}</Typography>
                            </Box>
                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Silver Phoenix</Typography>

                                <Typography variant="h2">{user?.hasNFT?.('PHOENIX_SILVER') ? '3%' : '0%'}</Typography>
                            </Box>
                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Standard Phoenix</Typography>
                                <Typography variant="h2">{user?.hasNFT?.('PHOENIX_STANDARD') ? '2%' : '0%'}</Typography>
                            </Box>
                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Stacker</Typography>
                                <Typography variant="h2">{hasStacker ? '1%' : '0%'}</Typography>
                            </Box>
                            <Box className={classes.divider} sx={{ marginTop: '16px', marginBottom: '16px' }} />

                            <Box className={classes.between} sx={{ marginBottom: '8px' }}>
                                <Typography variant="h2">Bonus without Stacker</Typography>
                                <Typography variant="h2">{getBonusWithoutStacker()}%</Typography>
                            </Box>
                            <Box className={classes.between}>
                                <Typography variant="h2">Bonus with Stacker</Typography>
                                <Typography variant="h2">{getBonusWithStacker()}%</Typography>
                            </Box>
                            <Typography variant="h6" sx={{ marginTop: '24px' }}>
                                By purchasing and owning a Stacker NFT, you are able to combine your bonus NFTs, the maximum additional
                                bonus you can receive in the Accelerator is 10% but this can be achieved through any combination of the
                                above bonus NFTs. For Example, if you own a Stacker, a Silver Phoenix and a Standard Phoenix, your
                                additional bonus would be 6%. Likewise, if you own a STacker, a Diamond NFT and a Standard Phoenix NFT, your
                                bonus would be 8%. This Bonus increases when you stake for one year or longer, for a total bonus of up to
                                25% for stakes that are placed for 5555.
                            </Typography>
                        </Card>
                    </Box>
                </Box>
            </Page>
        </React.Fragment>
    );
};

export default Stacker;
