import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    slider: {
        width: '90% !important',
        margin: `16px 0`,
    },
    textboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: `32px 0`,
    },
    textbox: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },
    max: {
        opacity: 0.5,
        fontSize: '0.8rem',
    },
}));

export default useStyles;
