import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import useTransactions from 'hooks/useTransactions';
import { useNavigate } from 'react-router';
import { openTx } from 'utils/open-etherscan';
import useStyles from './styles';
import Lottie from 'react-lottie';
import animation from './animation.json';

const StakeConfirmation = ({ stakeInfo, bonus, setInterfaceType }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { transactions } = useTransactions();

    const handleRedirectToStakes = () => {
        setInterfaceType('basic');
        navigate('/stake', { scroll: true });
    };

    const handleRedirectToCollider = () => {
        setInterfaceType('basic');
        navigate('/collider');
    };

    return (
        <div className={classes.root}>
            <Grid container style={{ height: '100%' }}>
                <Grid item md={12}>
                    <Typography className={classes.title} align="center">
                        Transaction Successfully Completed!
                    </Typography>
                    <Typography className={classes.description}>
                        Congratulations! Your stake has successfully been created and saved into the blockchain. You can view and follow
                        your new investment over on the "Stake" page by clicking the button below! You also earned an Axion Particles NFT
                        that can be&nbsp;
                        <span className={classes.collider} onClick={() => handleRedirectToCollider()}>
                            redeemed in the Collider
                        </span>
                        &nbsp; for a chance to mint a bonus stake!
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Lottie
                        speed={0.2}
                        width={300}
                        height={300}
                        options={{
                            loop: true,
                            animationData: animation,
                        }}
                    />
                </Grid>

                <Grid item md={12}>
                    <Grid container sx={{ justifyContent: 'space-evenly' }} spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Button fullWidth variant="outlined" onClick={() => handleRedirectToStakes()}>
                                View Stake
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Button fullWidth variant="outlined" onClick={() => handleRedirectToCollider()}>
                                Engage the Collider
                            </Button>
                        </Grid>
                    </Grid>
                    {transactions[0] && (
                        <Typography className={classes.openTx} onClick={() => openTx(transactions[0].id)} align="center">
                            Need more information? Click here to view on Polygonscan.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default StakeConfirmation;
