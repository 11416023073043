import React from 'react';
// Modules
import clsx from 'classnames';
// Components
import { Typography } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
// Modules
import useStyles from './styles';

const Stat = ({ percent, title = 'staked', value = '1,000', usdValue, legend, ...props }) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                {legend && <div className={classes.legend} style={{ backgroundColor: legend?.color }} />}
                <Typography variant="h6">{title}</Typography>
            </div>
            <Typography variant="h3" className={classes.value}>
                {value}
            </Typography>
            {usdValue && <Typography variant="h5">${usdValue}</Typography>}
            {percent && (
                <div className={classes.row}>
                    {percent?.positive ? <ArrowDropUp className={classes.positive} /> : <ArrowDropDown className={classes.negative} />}
                    <Typography
                        classes={{
                            root: clsx({
                                [classes.positive]: percent.positive,
                                [classes.negative]: !percent.positive,
                            }),
                        }}
                    >
                        {percent?.positive ? '+' : ''}
                        {percent?.value}%
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default Stat;
