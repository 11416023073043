import React, { useState } from 'react';
import useStyles from './styles';
import ClearIcon from '@mui/icons-material/Clear';
import AppsIcon from '@mui/icons-material/Apps';
import { SearchOutlined } from '@mui/icons-material';
import { TextField, MenuItem, Typography, Grid, Checkbox, IconButton, Tooltip, Popover, Slider } from '@mui/material';

const Toolbar = ({ filters, search, setSearch, activeFilters, setActiveFilters, clearFilters, gridSize, changeGridSize }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const MultiSelect = ({ name, value, onChange, options }) => (
        <TextField
            select
            fullWidth
            label={name}
            value={value}
            margin="dense"
            variant="outlined"
            InputProps={{
                className: classes.input,
            }}
            SelectProps={{
                multiple: true,
                MenuProps: {
                    classes: { paper: classes.menu },
                    disableScrollLock: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                },
                renderValue: (selected) => selected.map((s) => (isNaN(s) ? s : parseInt(s).numberWithCommas(0))).join(', '),
                onChange: (e) =>
                    onChange({
                        ...activeFilters,
                        [name]: e.target.value,
                    }),
            }}
        >
            {options.map((v, idx) => (
                <MenuItem value={v} key={idx} className={classes.menuItem}>
                    <Checkbox size="small" color="primary" checked={activeFilters[name]?.indexOf(v) > -1} />
                    <Typography>{isNaN(v) ? v : parseInt(v).numberWithCommas(0)}</Typography>
                </MenuItem>
            ))}
        </TextField>
    );

    return (
        <Grid container className={classes.toolbar} spacing={1}>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    value={search}
                    label="Search"
                    margin="dense"
                    variant="outlined"
                    onChange={(ev) => setSearch(ev.target.value)}
                    InputProps={{
                        endAdornment: <SearchOutlined className={classes.searchIcon} />,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <div className={classes.filters}>
                    {Object.keys(filters || {}).map((filter) => (
                        <MultiSelect
                            key={filter}
                            name={filter}
                            options={filters[filter]}
                            onChange={setActiveFilters}
                            value={activeFilters[filter] || []}
                        />
                    ))}

                    {Object.keys(activeFilters || {}).some((filter) => activeFilters[filter].length > 0) && (
                        <Tooltip title="Clear Filters" arrow placement="top">
                            <IconButton onClick={clearFilters}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Change Grid Size" arrow placement="top">
                        <IconButton onClick={(ev) => setOpen(ev.currentTarget)}>
                            <AppsIcon />
                        </IconButton>
                    </Tooltip>

                    <Popover
                        anchorEl={open}
                        open={Boolean(open)}
                        onClose={() => setOpen(null)}
                        classes={{ paper: classes.popover }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Slider min={1} step={1} max={12} value={gridSize} onChange={(_, value) => changeGridSize(value)} />
                    </Popover>
                </div>
            </Grid>
        </Grid>
    );
};

export default Toolbar;
