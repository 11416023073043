import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    confirmIcon: {
        fontSize: 20,
        color: theme.brand.teal,
    },
    paper: {
        backgroundColor: theme.brand.CardBG,
    },
    info: {
        color: theme.brand.NormalText,
    },
    warning: {
        color: theme.brand.orange,
    },
    success: {
        color: theme.brand.JavaGreen,
    },
    dialogTitle: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
