import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        opacity: 0.9,
        backgroundColor: theme.brand.CardBG,
    },
    icon: {
        cursor: 'pointer',
    },
    closeIconContainer: {
        padding: `2% 2% 0 2%`,
    },
}));

export default useStyles;
