import React, { useContext } from 'react';
import { Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import clsx from 'clsx';
import Button from 'components/Button';
import useTransactions from 'hooks/useTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { setChain } from 'redux/actions/chain';
import { setSnackbar } from 'redux/actions/snackbar';
// import { ContractContext } from 'web3/WagmiListener';
import { addPolygonToMetamask } from 'utils/add-to-metamask';
import { MATIC_CHAIN } from 'utils/variables';
import useStyles from './styles';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';

const Profile = ({ setShowTx }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { pendingTransactions } = useTransactions();
    const { theme } = useSelector((store) => store);
    const { address, isConnected } = useAccount();
    const { open } = useWeb3Modal();

    const switchToMatic = async () => {
        if (window.ethereum) {
            try {
                await addPolygonToMetamask();
                dispatch(setChain(MATIC_CHAIN));
                disconnect();
                dispatch(
                    setSnackbar({ message: 'Successfully switched to Polygon network. You may now connect your wallet.', type: 'success' }),
                );
            } catch (err) {
                dispatch(setSnackbar({ message: 'Unable to switch networks. Please try manually' }));
            }
        } else dispatch(setSnackbar({ message: 'Ethereum provider not found.' }));
    };

    const disconnectWallet = (ev) => {
        ev.stopPropagation();
        open();
    };

    if (isConnected && address) {
        if (pendingTransactions.length > 0)
            return (
                <Box className={clsx(classes.profile, classes.pendingTxContainer)} onClick={() => setShowTx(true)}>
                    <CircularProgress size={12} className={classes.whiteText} />

                    <Typography variant="h5" sx={{ marginLeft: 2 }} className={classes.whiteText}>
                        {pendingTransactions.length} Pending
                    </Typography>
                    <Typography style={{ marginTop: 4 }} className={classes.whiteText}>
                        <ChevronRightIcon fontSize="small" />
                    </Typography>
                </Box>
            );
        else
            return (
                <span className={classes.profile} onClick={() => setShowTx(true)}>
                    {address?.shortenAddress()}
                    {isConnected && (
                        <Tooltip title="Disconnect Wallet" placement="bottom-end" arrow>
                            <Close className={classes.disconnect} onClick={disconnectWallet} />
                        </Tooltip>
                    )}
                </span>
            );
    } else {
        // if (selectedNetwork?.id !== chain)
        //     return (
        //         <Button color="primary" variant="contained" onClick={() => switchToMatic()}>
        //             Switch Network
        //         </Button>
        //     );
        // else
        return (
            <Button color="primary" variant="contained" override={theme.mode === 'dark' ? 'blue' : 'supernova'} onClick={open}>
                Connect
            </Button>
        );
    }
};

export default Profile;
