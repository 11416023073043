// React
import React, { useContext, useEffect, useRef, useState } from 'react';

// Modules
import moment from 'moment';
import { DISCLAIMERS } from 'utils/disclaimers';

// Components
import Page from 'components/Page';
import Card from 'components/Card';
import PageTitle from './PageTitle';
import PortfolioWidget from './Portfolio';
import VentureTable from './VentureTable';
import Skeleton from 'components/Skeleton';
import Meta from '../../components/util/Meta';
import CardBody from 'components/Card/CardBody';
import CardTitle from 'components/Card/CardTitle';
import StakeTables from './StakeTables/StakeTables';
import { Box, Grid, Typography } from '@mui/material';
import SharesBarChart from 'components/SharesBarChart';
import Background from 'components/Background/Background';
import { BPD_AMOUNTS, BPD_TIMES } from 'utils/variables';
import DisclaimerModal from 'components/Modals/DisclaimerModal';
import { ContractContext } from 'web3/WagmiListener';
import { useLocation } from 'react-router-dom';

const Staking = (props) => {
    const stakeTableRef = useRef(null);
    const location = useLocation();
    const { user, userLoading } = useContext(ContractContext);
    const [sawDisclaimer, setSawDisclaimer] = useState(localStorage.getItem(DISCLAIMERS.STAKING.key));

    // For handling scrolling to the stake table
    // from the Accelerator's StakeConfirmation component.
    useEffect(() => {
        if (location.state?.scroll) {
            if (stakeTableRef.current) {
                setTimeout(() => {
                    stakeTableRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest',
                    });
                }, 1000);
            }
        }
    }, [location.state]);

    return (
        <React.Fragment>
            <Background />
            <Meta title="Axion | Staking Platform" />
            <DisclaimerModal open={!sawDisclaimer} onClose={() => setSawDisclaimer(true)} data={DISCLAIMERS.STAKING} />

            <Page header={<PageTitle />}>
                <Box>
                    <Box sx={{ marginBottom: '32px' }}>
                        <PortfolioWidget />
                    </Box>
                    {user && (
                        <Box sx={{ marginBottom: '32px' }}>
                            <VentureTable />
                        </Box>
                    )}
                    {userLoading && (
                        <Box sx={{ marginBottom: '32px' }}>
                            <Skeleton type="cardTable" />
                        </Box>
                    )}
                    <Box sx={{ marginBottom: '32px' }}>
                        <Card>
                            <CardTitle>
                                <Typography variant="h3">BigPayDay Yearly Amounts</Typography>
                            </CardTitle>
                            <CardBody>
                                <SharesBarChart
                                    values={BPD_AMOUNTS.map((value, index) => ({
                                        axn: value,
                                        date: moment(BPD_TIMES[index] * 1000).format('L'),
                                        paid: Date.now() / 1000 > BPD_TIMES[index],
                                    }))}
                                />
                            </CardBody>
                        </Card>
                    </Box>

                    {user && user?.stakes?.length > 0 && (
                        <Box sx={{ marginBottom: 24 }}>
                            <div ref={stakeTableRef}>
                                <StakeTables />
                            </div>
                        </Box>
                    )}
                </Box>
            </Page>
        </React.Fragment>
    );
};

export default Staking;
