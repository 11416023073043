import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import useStyles from './styles';
import CustomTable from 'components/CustomTable';
import { ContractContext } from 'web3/WagmiListener';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip, Typography, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const TableHeaders = ['Attempt Date', 'Multiplier', 'Particles', 'Result'];
const translation = {
    'Attempt Date': 'time',
    Multiplier: 'rarity',
    Particles: 'particles',
    Result: 'succeeded',
};

const ColliderHistory = () => {
    const classes = useStyles();
    const { user } = useContext(ContractContext);
    const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [events, setEvents] = useState([]);
    const [perPage, setPerPage] = useState(5);
    const [sort, setSort] = useState({ order: 'desc', orderBy: 'Particles' });

    useEffect(() => {
        if (user) setEvents(user.colliderResults);
        else setEvents([]);
    }, [user]);

    useEffect(() => {
        const key = translation[sort.orderBy];
        let ordered = [...events].sort((a, b) => (sort.order === 'desc' ? b[key] - a[key] : a[key] - b[key]));
        setItems(ordered.splice(perPage * page, perPage));
    }, [events, sort, page, perPage]);

    const handleChangePage = (e, page) => {
        setPage(page);
    };
    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };
    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    if (events.length === 0) return <div style={{ display: 'none' }} />;
    else
        return (
            <div className={classes.collection}>
                <Typography className={classes.title}>Past Collider Attempts ({events.length})</Typography>
                {events.length > 0 && (
                    <CustomTable
                        page={page}
                        count={items.length}
                        initialOrderIndex={2}
                        rowsPerPage={perPage}
                        onSort={handleOnSort}
                        headers={TableHeaders}
                        onChangePage={handleChangePage}
                        onChangeRows={handleChangeRows}
                        classes={{ root: classes.table }}
                        pagination={events.length >= perPage}
                        tooltips={{
                            Result: `Shows if the collision was a success or not. Each NFT has a 1 in 25 chance of colliding. 
                        If the collision succeeded, a new stake was created with the amount of particles in the NFT for the duration you choose.`,
                        }}
                        rows={items.map((item) => {
                            return {
                                columns: [
                                    <Tooltip title={isXS ? moment.unix(item.time).format('MMMM Do YYYY, h:mm:ss a') : ''} enterDelay={0}>
                                        <Typography style={{ whiteSpace: 'nowrap' }}>
                                            {moment.unix(item.time).format(isXS ? `MMM. DD, YYYY` : `MMM. DD, YYYY, h:mm A`)}
                                        </Typography>
                                    </Tooltip>,
                                    <Typography>{item.rarity}x</Typography>,
                                    <Typography>{item.particles.numberWithCommas(0)}</Typography>,
                                    <Typography
                                        className={clsx(classes.resultText, {
                                            [classes.green]: item.succeeded,
                                            [classes.red]: !item.succeeded,
                                        })}
                                    >
                                        {item.succeeded ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}&nbsp;
                                        {item.succeeded ? 'Success' : 'No Collision'}
                                    </Typography>,
                                ],
                            };
                        })}
                    />
                )}
            </div>
        );
};

export default ColliderHistory;
