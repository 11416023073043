import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            // textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    value: {
        fontSize: 14,

        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    legend: {
        height: 8,
        width: 30,
        borderRadius: 10,
        marginRight: theme.spacing(1),
    },
    positive: {
        color: theme.brand.JavaGreen,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
}));

export default useStyles;
