import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
