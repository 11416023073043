import React, { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { ContractContext } from 'web3/WagmiListener';

const PriceTooltip = ({ value, tooltipValue, children, suffix = 'AXN', tooltipSuffix = '', fixed = 0 }) => {
    const { contractInfo } = useContext(ContractContext);

    if (children) {
        return (
            <Tooltip
                placement="top"
                title={`$${(parseFloat(tooltipValue || value || 0) * (contractInfo?.axion?.usdPerAXN || 0)).numberWithCommas(
                    2,
                )}${tooltipSuffix}`}
                arrow
            >
                {children}
            </Tooltip>
        );
    }

    return (
        <Tooltip
            placement="top"
            title={`$${(parseFloat(tooltipValue || value || 0) * (contractInfo?.axion?.usdPerAXN || 0)).numberWithCommas(
                2,
            )}${tooltipSuffix}`}
            arrow
        >
            <span>
                {value.numberWithCommas(fixed)} {suffix}
            </span>
        </Tooltip>
    );
};

export default PriceTooltip;
