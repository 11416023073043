import React, { useContext, useState } from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
    CircularProgress,
} from '@mui/material';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';
import { QueryBuilder, Opacity } from '@mui/icons-material';
import Card from 'components/Card';
import Button from 'components/Button';

// Styles
import useStyles from './styles';
import { ContractContext } from 'web3/WagmiListener';
import { useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/react';

const PortfolioCard = ({ profile }) => {
    const classes = useStyles();
    const { theme } = useSelector((theme) => theme);
    const { user, userLoading, wallet } = useContext(ContractContext);
    const { open } = useWeb3Modal();

    const ConnectedUserStats = () => (
        <Card classes={{ root: classes.root }}>
            <div className={classes.portfolioTitle}>
                <Typography variant="h3">My Portfolio</Typography>
                <Typography variant="subtitle1">Status of AXN Assets</Typography>
            </div>
            <div className={classes.portfolioValue}>
                <div className={classes.row}>
                    <span className={classes.dollarSign}>$</span>
                    <span className={classes.valueDollars}>{profile.totalBalanceNoBPDUsd?.numberWithCommas?.(0) ?? 0.0}</span>
                    <span className={classes.valueCents}>.{profile?.totalBalanceUsdNoBPDUsdCents ?? 0.0}</span>
                </div>
                <Typography variant="h4">{profile?.totalBalanceNoBPD?.numberWithCommas?.() ?? 0.0} AXN</Typography>
                <Typography variant="h6" className={classes.mt6}>
                    staked + interest + liquid
                </Typography>
            </div>
            <div className={classes.portfolioStats}>
                <List classes={{ root: classes.list }}>
                    <ListItem classes={{ container: classes.listItem, root: classes.listItemRoot }}>
                        <ListItemAvatar>
                            <Avatar classes={{ root: classes.bgAxn }}>
                                <QueryBuilder />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ primary: classes.primary, secondary: classes.primary }}
                            primary="Staked"
                            secondary="Total Staked AXN"
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h3">{profile?.staked?.numberWithCommas() ?? 0.0}</Typography>
                            <Typography variant="h5">${profile?.stakedUsd?.numberWithCommas() ?? 0.0}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem classes={{ container: classes.listItem, root: classes.listItemRoot }}>
                        <ListItemAvatar>
                            <Avatar classes={{ root: classes.bgAxn }}>
                                <FaHandHoldingUsd />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ primary: classes.primary, secondary: classes.primary }}
                            primary="Active Interest"
                            secondary="Total interest from stakes"
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h3">{profile?.activeInterest?.numberWithCommas() ?? 0.0}</Typography>
                            <Typography variant="h5">${profile?.activeInterestUsd?.numberWithCommas() ?? 0.0}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem classes={{ container: classes.listItem, root: classes.listItemRoot }}>
                        <ListItemAvatar>
                            <Avatar classes={{ root: classes.bgAxn }}>
                                <Opacity />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ primary: classes.primary, secondary: classes.primary }}
                            primary="Liquid"
                            secondary="Holdings"
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h3">{profile?.balance?.numberWithCommas() ?? 0.0}</Typography>
                            <Typography variant="h5">${profile?.balanceUsd?.numberWithCommas() ?? 0.0}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem classes={{ container: classes.listItem, root: classes.listItemRoot }}>
                        <ListItemAvatar>
                            <Avatar classes={{ root: classes.bgAxn }}>
                                <GiTwoCoins />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ primary: classes.primary, secondary: classes.primary }}
                            primary="Big Pay Day"
                            secondary="Expected BPD earnings"
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h3">{profile?.activeBPD?.numberWithCommas() ?? 0.0}</Typography>
                            <Typography variant="h5">${profile?.activeBPDUsd?.numberWithCommas() ?? 0.0}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </div>
        </Card>
    );

    if (user) return <ConnectedUserStats />;
    else
        return (
            <>
                <Grid container direction="row" justify="center" alignItems="center" sx={{ position: 'relative' }}>
                    <Grid item xs={12} style={{ filter: 'blur(5px)', userSelect: 'none' }}>
                        <ConnectedUserStats />
                    </Grid>

                    {!userLoading && (
                        <Grid item sx={{ position: 'absolute', textAlign: 'center', width: '100%' }}>
                            <Typography variant="h2">Connect Your Wallet</Typography>
                            <Typography variant="h4">View the details of your Axion portfolio</Typography>

                            <br />
                            <Button
                                color="primary"
                                variant="contained"
                                override={theme.mode === 'dark' ? 'blue' : 'supernova'}
                                onClick={open}
                            >
                                Connect Your Wallet
                            </Button>
                        </Grid>
                    )}

                    {userLoading && (
                        <Grid item style={{ position: 'absolute', textAlign: 'center', width: '100%' }}>
                            <CircularProgress />
                            <Typography>Loading Portfolio...</Typography>
                        </Grid>
                    )}
                </Grid>
            </>
        );
};

export default PortfolioCard;
