import React, { useState, useContext, useRef, useMemo } from 'react';

// Modules
import BN from 'bn.js';
import web3 from 'web3';
import { useDispatch, useSelector } from 'react-redux';
import { ContractContext } from 'web3/WagmiListener';
import { trackStakeEvent, trackError } from 'utils/analytics';
import { CONFETTI_SETTINGS, MIN_STAKE_AMOUNT } from 'utils/variables';
import { PHOENIX_MINT_END_DATE, PHOENIX_PROMOTION } from 'containers/Accelerator/variables';
// Components
import useStyles from './styles';
import Card from 'components/Card';
import Reward from 'react-rewards';
import Button from 'components/Button';
import BPDChecker from 'components/BPDChecker';
import { Typography } from '@mui/material';
import StakeInput from 'components/StakeInput';
import { setSnackbar } from 'redux/actions/snackbar';

const StakeCard = ({ onStake, onError }) => {
    const rewardRef = useRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { theme } = useSelector((store) => store);
    const { user } = useContext(ContractContext);

    const [staking, setStaking] = useState(false);
    const [days, setDays] = useState({ value: '', valid: true });
    const [amount, setAmount] = useState({ value: '50000', valid: true });

    const handleStake = async () => {
        if (amount.value < MIN_STAKE_AMOUNT) {
            dispatch(setSnackbar({ message: `You must stake at least ${MIN_STAKE_AMOUNT.toLocaleString()} AXN.` }));
            return;
        }

        const _days = days.value;
        const _amount = web3.utils.toWei(amount.value);

        setStaking(true);

        try {
            await onStake(_amount, _days);
            if (+_days === 5555) rewardRef?.current?.rewardMe();
            trackStakeEvent({ amount: _amount, days: _days, type: 'stake' });
        } catch (error) {
            onError(error.message);
            trackError({ type: 'stake', description: error.message });
        } finally {
            setStaking(false);
        }
    };

    const stakeDisabled = () => {
        if (staking) return true;
        if (days.value <= 0) return true;
        if (user && amount.value) return new BN(web3.utils.toWei(`${user?.balance}`)).lt(new BN(web3.utils.toWei(`${amount.value}`)));
        return true;
    };

    const phoenixPromotionEligibility = useMemo(() => {
        if (Date.now() / 1000 >= PHOENIX_MINT_END_DATE) return null;
        if (parseFloat(days.value) < 365) return PHOENIX_PROMOTION[0];
        return PHOENIX_PROMOTION.find(
            (promotion) => parseFloat(amount.value) >= promotion.stake.minAXN && parseFloat(amount.value) < promotion.stake.maxAXN,
        );
    }, [amount, days]);

    return (
        <Card>
            <div className={classes.confetti}>
                <Reward ref={rewardRef} type="confetti" config={{ ...CONFETTI_SETTINGS }}>
                    <></>
                </Reward>
            </div>

            <div className={classes.stakerTitle}>
                <div className={classes.column}>
                    <Typography variant="h3">Add a new stake</Typography>
                    <Typography variant="subtitle1">Get more shares when you stake longer!</Typography>
                </div>
                <div className={classes.bpd}>
                    <BPDChecker amount={amount.value} days={days.value} stakePage />
                </div>
            </div>
            <StakeInput
                amount={amount}
                onChangeAmount={setAmount}
                stakeDays={days}
                onChangeDays={setDays}
                phoenixPromotion={phoenixPromotionEligibility}
            />
            <div className={classes.stakeFooter}>
                <Button
                    fullWidth
                    color="primary"
                    loading={staking}
                    variant="contained"
                    onClick={handleStake}
                    disabled={stakeDisabled()}
                    override={theme.mode === 'dark' ? 'blue' : 'supernova'}
                >
                    Create Stake
                </Button>
            </div>
        </Card>
    );
};

export default StakeCard;
