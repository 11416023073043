import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Close } from '@mui/icons-material';
import { MdFilterFrames } from 'react-icons/md';
import TuneIcon from '@mui/icons-material/Tune';
import SlideUp from 'components/Transitions/SlideUp';
import { UTILITY_NFT_OPENSEA, PARTICLE_NFT_OPENSEA, GALAXY_NFT_OPENSEA } from 'utils/variables';
import { Card, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const NFTModal = ({ open, onClose, nft, activeFilters, setActiveFilters }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const openInfo = () => {
        if (nft.type === 'particle') {
            navigate('/collider', {
                selected: {
                    metadata: nft,
                },
            });
        } else window.open(nft.external_url);
    };

    const openOpensea = () => {
        if (nft.type === 'particle') window.open(PARTICLE_NFT_OPENSEA + nft.id);
        else if (nft.type === 'utility') window.open(UTILITY_NFT_OPENSEA + nft.id);
        else if (nft.type === 'galaxy') window.open(GALAXY_NFT_OPENSEA + nft.id);
    };

    const setFilter = (type, value) => {
        const copy = { ...activeFilters };
        if (copy[type]) {
            if (copy[type]?.includes(value)) copy[type] = copy[type].filter((i) => i !== value);
            else copy[type].push(value);
            setActiveFilters(copy);
        }
    };

    return (
        <Dialog
            fullWidth
            scroll="body"
            maxWidth="md"
            open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <video controls={nft?.type !== 'particle'} width="100%" autoPlay loop className={classes.art} poster={nft?.image}>
                        {nft?.animation_url && <source src={nft?.animation_url} type="video/webm" />}
                        {nft?.image && <img src={nft?.image} alt="nft art" />}
                    </video>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.root}>
                        <div>
                            <div className={classes.contentContainer}>
                                <div className={classes.dialogTitle}>
                                    <MdFilterFrames className={classes.icon} />
                                    <Typography variant="h3">{nft?.name}</Typography>
                                </div>
                                <IconButton className={classes.close} onClick={onClose}>
                                    <Close />
                                </IconButton>
                            </div>
                            <div className={classes.descriptionContainer}>
                                <Typography className={classes.descriptionText}>{nft?.description}</Typography>
                            </div>
                        </div>

                        {nft?.properties?.length > 0 && (
                            <div className={classes.traitContainer}>
                                <div className={classes.traitTitle}>
                                    <TuneIcon className={classes.icon} />
                                    <Typography variant="h3">Traits &amp; Functionality</Typography>
                                </div>
                                <Grid container spacing={2} justify="center">
                                    {nft?.properties?.map((p) => (
                                        <Grid
                                            item
                                            xs={6}
                                            key={p.value}
                                            onClick={() => setFilter(p.trait_type, p.value)}
                                            className={clsx(classes.traitCard, {
                                                [classes.active]: activeFilters[p.trait_type]?.includes(p.value),
                                            })}
                                        >
                                            <Card elevation={6} className={classes.trait}>
                                                <Typography className={classes.traitType}>{p.trait_type}</Typography>
                                                <Typography className={classes.traitValue}>
                                                    {isNaN(p.value) ? p.value : parseInt(p.value).numberWithCommas(0)}
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}

                        <div className={classes.actionButtonContainer}>
                            <div className={classes.actionButton} onClick={openInfo}>
                                <Typography>{nft?.type === 'particle' ? 'Use in Collider' : 'Extended Details'}</Typography>
                            </div>
                            <div className={classes.actionButton} onClick={openOpensea}>
                                <Typography>Open Opensea</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default NFTModal;
