import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { sample } from 'lodash';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import clsx from 'classnames';

const PromoBanner = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [banner, setBanner] = useState(null);
    const theme = useTheme();
    const openWindow = (url) => window.open(url, '_blank');
    const { launches } = useSelector((store) => store);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (launches?.launches?.length > 0 && !banner) {
            const active = launches?.launches?.filter?.((l) => l['al-state'] === 'current' || l['banner-link']) ?? false;
            setBanner(sample(active));
        }

        // eslint-disable-next-line
    }, [launches]);

    const handleLink = (link) => {
        if (link.includes('stake.axion.network')) {
            link = link.replace('https://stake.axion.network/', '');
            navigate(`/${link.replace('/', '')}`);
        } else openWindow(link + '?utm_source=staking-platform');
    };

    if (!banner || !banner['al-portal-banner']) return null;
    return (
        <img
            loading="lazy"
            className={clsx({ [classes.image]: true, [classes.light]: theme.mode !== 'dark' })}
            alt="staking portal banner"
            onClick={() => handleLink(banner['banner-link'] || banner.link)}
            src={`https://axion.network/${
                isMobile ? banner['al-portal-banner-mobile'] || banner['al-portal-banner'] : banner['al-portal-banner']
            }`}
        />
    );
};

export default PromoBanner;
