import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    nftContainer: {
        borderRadius: 10,
        cursor: 'pointer',
        margin: theme.spacing(1),
        backgroundColor: `${theme.brand.border}`,

        '&:hover': {
            boxShadow: `0 0 10px ${theme.brand.primary}`,
        },
    },
    textContainer: {
        padding: theme.spacing(1),
    },
    nftBalance: {
        textAlign: 'right',
    },
    art: {
        width: '100%',
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
}));

export default useStyles;
