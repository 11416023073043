import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { useDrag } from 'react-dnd';
import { Typography } from '@mui/material';

const ParticleCard = ({ nft, selected }) => {
    const classes = useStyles();

    // eslint-disable-next-line
    const [_, dragRef] = useDrag({ type: 'nft', item: nft });

    return (
        <div className={classes.particleCard} ref={!selected ? dragRef : null}>
            <img
                alt={nft.name}
                src={nft.metadata.image}
                className={clsx(classes.nft, {
                    [classes.selected]: selected,
                })}
            />
            <Typography className={classes.nftName}>
                Particle Count
                <br />
                {nft.metadata.particles?.numberWithCommas(0)}
            </Typography>
        </div>
    );
};

export default ParticleCard;
