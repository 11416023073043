import React, { useContext } from 'react';
// Modules
import { setSnackbar } from 'redux/actions/snackbar';
import { useDispatch } from 'react-redux';
import { ContractContext } from 'web3/WagmiListener';
import getProfileStats from 'utils/get-profile-stats';
// Components
import { Grid } from '@mui/material';
import { RiPercentLine } from 'react-icons/ri';
import { AiOutlinePieChart } from 'react-icons/ai';
import StatCard from 'components/StatCard';
import PortfolioCard from './PortfolioCard';
import StakeCard from './StakeCard';

// Styles
import useStyles from './styles';

const PortfolioWidget = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user, library, getWalletDetails, contractInfo } = useContext(ContractContext);

    /** Handle functions */
    const handleStake = async (amount, days) => {
        await library.Staking.stake(amount, days);
        await getWalletDetails();
    };
    const handleError = (message) => {
        dispatch(setSnackbar({ message }));
    };

    /** Page Data */
    const profile = getProfileStats(user, contractInfo?.axion?.usdPerAXN, contractInfo?.axion?.totalShares);

    return (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12} lg={5} style={{ display: user ? 'block' : 'flex' }}>
                <PortfolioCard profile={profile} />
            </Grid>
            <Grid item xs={12} lg={7}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <StatCard
                            title={'Shares'}
                            variant={'primary'}
                            Icon={AiOutlinePieChart}
                            notes={'Amount of AXN shares you own'}
                            body={profile?.shares?.numberWithCommas?.(0)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StatCard
                            Icon={RiPercentLine}
                            variant={'secondary'}
                            title={'Share Percentage'}
                            notes={'Represents your share of the pool'}
                            body={isNaN(profile?.sharesPercent) ? (0).toFixed(5) : profile?.sharesPercent?.toFixed?.(5)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <StakeCard onError={handleError} onStake={handleStake} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PortfolioWidget;
