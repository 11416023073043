import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    numberOfParticles: {
        borderRadius: 5,
        border: `2px solid ${theme.brand.border}`,
    },
    numberOfParticlesValue: {
        fontWeight: 500,
        fontSize: '1.5rem',
        textAlign: 'center',
        padding: theme.spacing(0.5),
        color: theme.brand.colliderOrange,
    },
    chance: {
        fontWeight: 500,
        fontSize: '1.5rem',
        textAlign: 'center',
        padding: theme.spacing(0.5),
        color: theme.brand.colliderBlue,
    },
    stakeDaysContainer: {
        marginTop: theme.spacing(3),
    },
    subValue: {
        fontWeight: 300,
        fontSize: '0.8rem',
        textAlign: 'center',
    },
    value: {
        fontWeight: 500,
        textAlign: 'center',
    },
    totals: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    green: {
        color: theme.brand.JavaGreen,
    },
    explain: {
        fontWeight: 300,
        fontSize: '0.8rem',
        textAlign: 'center',
        color: theme.brand.textSecondary,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    startButton: {
        border: `5px solid ${theme.brand.border}`,
        backgroundColor: theme.brand.colliderOrange,
        '&:hover': {
            filter: 'brightness(120%)',
            backgroundColor: theme.brand.colliderOrange,
        },
    },
    stakeLengthText: {
        fontWeight: 300,
        fontSize: '0.8rem',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },
}));

export default useStyles;
